<template>
  <v-dialog v-model="isOpen" max-width="1200px">
    <v-card>
      <v-card-title>Open AI system settings</v-card-title>
      <v-card-text>
        <v-row cols="2">
          <v-col>
            <v-textarea
              :disabled="!isInsightsEditor"
              rows="11"
              :model-value="settings.defaultMessage"
              label="System message"
              auto-grow
              @update:model-value="settings.defaultMessage = $event"
            />
          </v-col>
          <v-col>
            <v-row>
              <v-select
                :disabled="!isInsightsEditor"
                class="mt-3"
                :model-value="settings.model"
                label="Model"
                :items="['gpt-4']"
              />
            </v-row>
            <v-row>
              <v-text-field
                :model-value="settings.temperature"
                :disabled="!isInsightsEditor"
                label="Temperature"
                type="number"
                @update:model-value="settings.temperature = Number($event)"
              />
            </v-row>
            <v-row>
              <v-text-field
                :disabled="!isInsightsEditor"
                :model-value="settings.maxTokens"
                label="Max tokens"
                type="number"
                @update:model-value="settings.maxTokens = Number($event)"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="caption">WARNING: Setting changes are global</div>

        <v-spacer />
        <v-btn color="primary" :text="settingsHaveChanged() ? 'Cancel' : 'Close'" @click="cancelClick()" />
        <v-btn
          color="primary"
          text="Save"
          :disabled="!unsavedSettingsValid || !settingsHaveChanged() || !isInsightsEditor"
          @click="okClick()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, SettingsStore } from '#stores'

  @Component({})
  class DaphneSettingsDialog extends Vue {
    public isOpen = false

    public get unsavedSettingsValid() {
      return this.settings.temperature && this.settings.model && this.settings.maxTokens && this.settings.defaultMessage
    }

    public settings: {
      temperature: number | null
      model: string | null
      maxTokens: number | null
      defaultMessage: string | null
    } = {
      temperature: null,
      model: null,
      maxTokens: null,
      defaultMessage: null,
    }

    public unsavedSettings: {
      temperature: number | null
      model: string | null
      maxTokens: number | null
      defaultMessage: string | null
    } = {
      temperature: null,
      model: null,
      maxTokens: null,
      defaultMessage: null,
    }

    private readonly settingStore = new SettingsStore()
    private readonly appStore = new AppStore()

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public settingsHaveChanged() {
      return !(
        (this.settings.defaultMessage ? this.settings.defaultMessage === this.unsavedSettings.defaultMessage : false) &&
        (this.settings.temperature ? this.settings.temperature === this.unsavedSettings.temperature : false) &&
        (this.settings.model ? this.settings.model === this.unsavedSettings.model : false) &&
        (this.settings.maxTokens ? this.settings.maxTokens === this.unsavedSettings.maxTokens : false)
      )
    }

    public async open() {
      this.settings = this.settingStore.getOpenAiSettings
      this.unsavedSettings = { ...this.settings }
      this.isOpen = true
    }

    public cancelClick() {
      this.isOpen = false
    }

    public async okClick() {
      await this.settingStore.updateOpenAiSettings(this.settings)
      this.isOpen = false
    }
  }

  export default toNative(DaphneSettingsDialog)
</script>
