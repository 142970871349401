<template>
  <v-dialog v-if="$featureEnabled('daphneIntegration')" v-model="isOpen" max-width="1200px" scrollable>
    <v-card>
      <v-card-title class="headline">
        <span class="mr-2">Generate New Messages</span>

        <v-spacer />
        <v-btn color="primary" icon="mdi-information-outline" @click="infoDialog = true" />
      </v-card-title>
      <v-card-text>
        <v-row cols="12">
          <v-col cols="5">
            <v-row>
              <v-col>
                <v-textarea
                  rows="11"
                  label="Description of the message"
                  :model-value="prompt"
                  auto-grow
                  @update:model-value="prompt = $event"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col>
                <div class="text-pre-wrap">
                  <div class="mx-2" style="max-height: 480px; min-height: 320px; overflow: auto">
                    <v-textarea
                      rows="11"
                      :model-value="newTitle"
                      label="Generated title"
                      auto-grow
                      @update:model-value="newTitle = $event"
                    />
                  </div>
                </div>
              </v-col>
              <v-col>
                <v-textarea
                  rows="11"
                  :model-value="newMessage"
                  :disabled="generating"
                  label="Generated message"
                  auto-grow
                  @update:model-value="newMessage = $event"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-alert v-if="generating" variant="text" type="info">Generation in progress...</v-alert>
                <v-alert v-if="errorMessage" variant="text" type="error">{{ errorMessage }}</v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="caption">WARNING: This feature is still experimental</div>

        <v-spacer />
        <v-btn
          color="primary"
          variant="plain"
          text="Generate"
          :disabled="generating || !prompt"
          @click="generateVariant()"
        />
        <v-btn color="primary" text="Cancel" variant="plain" :disabled="saving" @click="onCancelClicked()" />
        <v-btn
          color="primary"
          text="Save"
          :loading="saving"
          :disabled="!newMessage || !newTitle"
          variant="plain"
          @click="onOkClicked()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="infoDialog" max-width="1200px">
    <v-card>
      <v-card-title class="headline">Raw Daphne response</v-card-title>
      <v-card-text>
        <div v-for="row of (rawResponse ?? '').split('\n')" :key="row">
          <span>{{ row }}</span>
          <br />
        </div>
        <span v-if="inspirationFile" class="my-3">Inspiration file: {{ inspirationFile }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text="Close" @click="infoDialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { getApp } from 'firebase/app'
  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { generateNewMessage } from '#views/messages/utilities'

  import { AppStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class GenerateNewMessageDialog extends Vue {
    @Prop() public insightId!: string
    @Prop() public insightPath!: string

    public isOpen = false
    public saving = false
    public generating = false

    private appStore = new AppStore()

    public newMessage = ''
    public newTitle = ''
    public inspirationFile: string | undefined = undefined
    public prompt = ''
    public usedPrompt: string | null = null
    public errorMessage = ''
    public infoDialog = false
    public rawResponse = ''

    public insight: Insight | null = null
    public messageOrder: number = 0

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    @Debounce(200)
    public async generateVariant() {
      this.generating = true

      if (this.prompt) {
        this.usedPrompt = this.prompt
      }

      const functions = getFunctions(getApp(), 'europe-west1')

      try {
        const response: any = await httpsCallable(functions, 'fetchNewInsightFromDaphne', { timeout: 100000 })({
          prompt: this.prompt,
        })

        console.log(response)

        this.newMessage = response.data.text
        this.newTitle = response.data.title
        this.inspirationFile = response.data.inspiration
        this.rawResponse = response.data.rawResponse
      } catch (error: any) {
        this.errorMessage = error.message
      }

      this.generating = false
    }

    public async open(insight: Insight, messageOreder?: number) {
      this.messageOrder = messageOreder ?? 0
      this.insight = insight
      this.isOpen = true
    }

    public async onOkClicked() {
      this.saving = true

      const newMessageId = await generateNewMessage(
        this.newTitle,
        this.newMessage,
        this.insightId,
        this.usedPrompt,
        this.messageOrder,
      )

      this.isOpen = false
      this.saving = false

      this.$router.push(`/${this.$route.path.split('/')[1]}/${this.insightId}/messages/${newMessageId}`)
    }

    public onCancelClicked() {
      this.isOpen = false
    }
  }

  export default toNative(GenerateNewMessageDialog)
</script>
