<template>
  <div v-if="activeOverride" class="d-flex flex-column align-baseline">
    <div class="flex-grow-1" style="width: 100%">
      <v-form ref="rolloutForm" v-model="validForm" validate-on="lazy">
        <v-alert v-if="overrideRolloutStatus(activeOverride) === 'PAUSED'" class="mb-4" color="warning">
          Feature is enabled and rollout has been launched, you should be
          <b>very</b>
          mindful of what you edit!
        </v-alert>

        <v-alert v-else-if="overrideRolloutStatus(activeOverride) === 'FINALIZED'" class="mb-4" color="warning">
          Feature is enabled and rollout has been completed, you should be
          <b>very</b>
          mindful of what you edit!
        </v-alert>

        <div v-if="!activeOverride.metadata?.informative?.labels.rollout" class="text-button">Metadata</div>

        <v-alert
          v-if="
            !activeOverride.metadata?.informative?.labels.rollout &&
            !activeOverride.metadata?.informative?.additionalData?.createdAt
          "
          color="info"
          class="mt-2 mb-4"
        >
          Good name is short, but describes the use case, e.g. CLOSED_BETA_TESTERS_ROLLOUT, PUBLIC_HOME_CARD_EXPERIMENT,
          etc.
          <br />
          Description can provide more detailed information.
        </v-alert>

        <v-text-field
          v-if="activeOverride && !activeOverride.metadata?.informative?.labels.rollout"
          autofocus
          class="uppercased"
          label="Rollout name *"
          hint="Name is permanent and can not be changed!"
          placeholder="Enter name (will be uppercased)"
          :rules="[(v: string) => verifyName(v)]"
          :disabled="!!activeOverride.metadata?.informative?.additionalData?.createdAt"
          :model-value="activeOverride.metadata?.uid?.slice(0, -7) || ''"
          @update:model-value="activeOverride.metadata!.uid = $event + '_suffix'"
          @blur="updateName()"
        />

        <v-textarea
          v-if="!activeOverride.metadata?.informative?.labels.rollout"
          v-model="activeOverride.metadata!.informative!.description"
          label="Description *"
          rows="3"
          auto-grow
          class="mt-1"
          @blur="updateInfo()"
        />

        <v-text-field
          v-if="!activeOverride.metadata?.informative?.labels.rollout"
          v-model="activeOverride.metadata!.informative!.referenceUrls[0]"
          label="More info link *"
          type="url"
          append-icon="mdi-open-in-new"
          :rules="[(v: string) => !v || (v.startsWith('https://') && v.length > 8)]"
          @blur="updateInfo()"
          @click:append="openUrl(activeOverride.metadata!.informative!.referenceUrls[0])"
        />

        <v-autocomplete
          v-if="!activeOverride.metadata?.informative?.labels.rollout"
          v-model="activeOverride.metadata!.informative!.labels.project"
          label="Project *"
          item-title="name"
          item-value="id"
          :return-object="false"
          :items="validRolloutProjects"
          @blur="updateInfo()"
        >
          <template #append>
            <v-tooltip
              max-width="390"
              location="top end"
              text="Project controls who can edit the rollout so choose it carefully, see docs for more info"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props">mdi-information-outline</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-autocomplete>

        <v-alert
          v-if="!!activeOverride?.metadata?.informative?.labels?.project && !isProjectEditor(activeOverride)"
          class="mb-6"
          color="warning"
        >
          You do not have rights to the selected project! You need project editor rights to create rollouts /
          experiments. See the project details for owner / admin contact info and ask one of them to add you to the
          project editors.
        </v-alert>

        <div class="text-button my-2">Settings</div>

        <v-select
          label="Type"
          :return-object="false"
          :items="rolloutTypes"
          :model-value="activeOverride.metadata?.informative!.labels.template"
          :disabled="!!activeOverride.metadata?.informative?.additionalData?.createdAt"
          @update:model-value="updateType($event)"
        />

        <RolloutStages
          v-if="overrides.length === 1"
          :active="active"
          :override="activeOverride"
          :references="references"
        />

        <RolloutGroups v-else :active="active" :feature="feature" :overrides="overrides" :references="references" />

        <div v-for="(override, overrideIdx) in overrides" :key="overrideIdx">
          <template v-if="!overrides || active == overrideIdx">
            <div class="text-button my-2">Segment</div>

            <RolloutTarget
              :active="active"
              :override="activeOverride"
              :references="references"
              :unauthenticated="hasUnauthenticatedFlags"
              @validate="emitValidate($event)"
            />

            <div class="text-button my-2">Overrides</div>

            <RolloutParams :feature="feature" :override="override" />

            <div
              v-for="(reference, referenceIdx) in references"
              :key="`${overrideIdx}-${referenceIdx}`"
              class="parameters mb-n5"
            >
              <RolloutParams
                :references="references"
                :feature="reference.feature"
                :override="reference.overrides[overrideIdx]"
                @delete="deleteFeatureOverride(referenceIdx)"
              />
            </div>

            <RolloutParams
              v-if="addReference"
              :feature="feature"
              :references="references"
              @delete="deleteFeatureOverride(references.length)"
              @select="selectFeatureOverride(references.length, $event)"
            />

            <v-row>
              <v-col class="mt-2 d-flex flex-row justify-end">
                <v-btn
                  id="add-override"
                  size="small"
                  color="primary"
                  :disabled="
                    hasUnauthenticatedFlags ||
                    !!activeOverride.metadata?.informative?.labels?.rollout ||
                    !!activeOverride.metadata?.informative?.additionalData?.createdAt
                  "
                  @click="appendFeatureOverride()"
                >
                  Add another feature flag
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </div>

        <!-- <template
          v-if="
            isWaltariAdmin &&
            !!activeOverride.metadata?.informative?.additionalData?.createdAt &&
            !activeOverride.metadata?.informative?.labels.rollout &&
            overrides.length === 1 &&
            references.length === 0
          "
        >
          <div class="text-button">Migration</div>

          <v-btn
            block
            class="my-4"
            color="error"
            variant="elevated"
            :disabled="
              isLoading || !!findFeatureOverride(feature, activeOverride.metadata!.informative!.labels.template.split('-')[1])
            "
            :text="'Set as default ' + activeOverride.metadata?!informative!.labels.template.split('-')[1] + ' rollout'"
            @click="migrateFeatureRollout(activeOverride.metadata!.informative!.labels.template.split('-')[1])"
          />
          <v-btn
            v-if="activeOverride.metadata!.informative!.labels.template === 'percentage-staging'"
            block
            class="my-4"
            color="error"
            variant="elevated"
            text="Set as default experimental rollout"
            :disabled="isLoading || !!findFeatureOverride(feature, 'experimental')"
            @click="migrateFeatureRollout('experimental')"
          />
          <v-btn
            v-if="
              feature.metadata.informative?.labels.strategy === 'default' &&
              activeOverride.metadata!.informative!.labels.template === 'percentage-release'
            "
            block
            class="my-4"
            color="error"
            variant="elevated"
            text="Set as default Ouranians rollout"
            :disabled="isLoading || !!findFeatureOverride(feature, 'ouranians')"
            @click="migrateFeatureRollout('ouranians')"
          />
          <v-btn
            v-if="
              feature.metadata.informative?.labels.strategy === 'advanced' &&
              (activeOverride.metadata!.informative!.labels.template === 'percentage-release' ||
                activeOverride.metadata!.informative!.labels.template === 'percentage-release-ios')
            "
            block
            class="my-4"
            color="error"
            variant="elevated"
            text="Set as default release iOS rollout"
            :disabled="isLoading || !!findFeatureOverride(feature, 'release-ios')"
            @click="migrateFeatureRollout('release-ios')"
          />
          <v-btn
            v-if="
              feature.metadata.informative?.labels.strategy === 'advanced' &&
              (activeOverride.metadata!.informative!.labels.template === 'percentage-release' ||
                activeOverride.metadata!.informative!.labels.template === 'percentage-release-android')
            "
            block
            class="my-4"
            color="error"
            variant="elevated"
            text="Set as default release Android rollout"
            :disabled="isLoading || !!findFeatureOverride(feature, 'release-android')"
            @click="migrateFeatureRollout('release-android')"
          />
        </template> -->
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
  import slug from 'slug'

  import { ComponentPublicInstance } from 'vue'

  import { useGoTo } from 'vuetify'
  import { VForm } from 'vuetify/lib/components/VForm/index.mjs'

  import { Component, Emit, Prop, Ref, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Context } from '@jouzen/control-api/metadata'

  import { rolloutTypes } from '#views/features/constants'

  import {
    createOverride,
    findFeatureOverride,
    forEachFeatureOverride,
    overrideRolloutStatus,
    updateOverridesFromTemplate,
  } from '#views/features/utilities'
  import { createProjectData, getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore, ReleasesStore, SegmentsStore } from '#stores'

  import { Feature, Override, Reference } from '#types'

  import { nanoId } from '#utilities'

  @Component({})
  class RolloutEditor extends Vue {
    @Prop() public active!: number

    @Prop() public feature!: Feature
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public validForm = true
    public validTarget = true

    public addReference = false

    public readonly rolloutTypes = rolloutTypes

    public readonly getProjectKey = getProjectKey
    public readonly isProjectEditor = isProjectEditor
    public readonly createProjectData = createProjectData
    public readonly findFeatureOverride = findFeatureOverride
    public readonly overrideRolloutStatus = overrideRolloutStatus

    private readonly appStore = new AppStore()

    private readonly projectsStore = new ProjectsStore()
    private readonly featuresStore = new FeaturesStore()
    private readonly segmentsStore = new SegmentsStore()
    private readonly releasesStore = new ReleasesStore()

    private latestVersions: any = { ios: {}, android: {} }

    @Ref() private readonly rolloutForm: ComponentPublicInstance<VForm> | null = null

    public get isLoading() {
      return this.featuresStore.loading || this.segmentsStore.loading
    }

    public get allFeatures() {
      return this.featuresStore.features
    }

    public get allCriterias() {
      return this.segmentsStore.criterias
    }

    public get activeOverride() {
      return this.overrides[this.active]
    }

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get validRolloutProjects() {
      return this.projectsStore.projects.filter(
        (p) => (p.key && p.sections.features) || p.id === this.activeOverride.metadata?.informative?.labels?.project,
      )
    }

    public get hasUnauthenticatedFlags() {
      return (
        this.feature.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
        this.feature.metadata?.contextSpec?.oneOf?.contexts?.contexts.includes(Context.UNAUTHENTICATED_DEVICE)
      )
    }

    @Emit('validate')
    public emitValidate(valid?: boolean) {
      if (valid !== undefined) {
        this.validTarget = valid
      }

      return this.validTarget !== false && this.validForm !== false ? true : null
    }

    @Watch('validForm')
    protected validFormChanged() {
      this.emitValidate()
    }

    @Watch('overrides', { deep: true })
    protected overridesChanged() {
      this.emitValidate()
    }

    @Watch('references', { deep: true })
    protected referencesChanged() {
      this.emitValidate()
    }

    public async mounted() {
      this.latestVersions.ios = await this.releasesStore.fetchLatestVersion('ios')
      this.latestVersions.android = await this.releasesStore.fetchLatestVersion('android')
    }

    public openUrl(url: string) {
      if (url) {
        window.open(url, '_blank')
      }
    }

    public updateName() {
      const uid = slug(this.activeOverride.metadata?.uid?.slice(0, -7) || '', '_')

      this.overrides.forEach((o) => (o.metadata!.uid = uid ? uid.replace(/^(\d|_)+/g, '') + '_' + nanoId() : ''))
    }

    public updateInfo() {
      this.overrides.forEach((o) => {
        o.metadata!.informative!.description = this.activeOverride.metadata!.informative!.description

        o.metadata!.informative!.referenceUrls = this.activeOverride.metadata!.informative!.referenceUrls

        o.metadata!.informative!.labels!.project = this.activeOverride.metadata!.informative!.labels!.project
      })
    }

    public verifyName(name: string) {
      let valid = true

      if (name.length > 40) {
        return 'Name can not be over 40 characters long'
      } else if (!this.activeOverride.metadata?.informative?.additionalData?.createdAt) {
        for (const f of this.allFeatures) {
          forEachFeatureOverride(f, (o) => {
            if (o.metadata?.uid?.slice(0, -7)?.toLowerCase() === name.toLowerCase()) {
              valid = false
            }
          })
        }
      }

      return valid || 'Rollout with this name already exists'
    }

    public updateType(type: string) {
      const uid = this.activeOverride.metadata?.uid?.slice(0, -6)

      const info = this.activeOverride.metadata?.informative?.description || ''
      const link = this.activeOverride.metadata?.informative?.referenceUrls[0] || ''
      const project = this.activeOverride.metadata?.informative?.labels?.project || ''

      this.$router.replace(this.$route.path.replace(/\/\d+$/, '/0'))

      this.$nextTick(() => {
        if (type.startsWith('percentage')) {
          this.overrides.length = 1

          this.overrides[0] = { ...createOverride(this.feature) }

          this.overrides[0].metadata!.uid = uid ? uid + nanoId() : ''
        } else {
          this.overrides.length = type === 'experiment-ab-testing' ? 2 : 3

          for (let i = 0; i < this.overrides.length; i++) {
            this.overrides[i] = { ...createOverride(this.feature) }

            this.overrides[i].metadata!.uid = uid ? uid + nanoId() : ''
          }
        }

        updateOverridesFromTemplate(this.overrides, type, this.latestVersions)

        this.overrides.forEach((o) => {
          o.metadata!.informative!.description = info
          o.metadata!.informative!.referenceUrls = [link]
          o.metadata!.informative!.labels.project = project
        })
      })
    }

    public appendFeatureOverride() {
      this.addReference = true

      window.setTimeout(() => this.goTo('#add-override', { container: '#rollout-panel' }), 10)
    }

    public deleteFeatureOverride(index: number) {
      this.addReference = false

      this.references.splice(index, 1)

      window.setTimeout(() => this.goTo('#add-override', { container: '#rollout-panel' }), 10)
    }

    public selectFeatureOverride(reference: number, value: string) {
      this.addReference = false

      const feature = this.allFeatures.find(
        (f) => f.metadata?.name === value.split('/')[1] && getProjectKey(f) === value.split('/')[0],
      )

      const overrides = this.overrides.map(() => createOverride(feature))

      if (feature) {
        this.references.push({
          feature,
          overrides,
        })
      }

      window.setTimeout(() => this.goTo('#add-override', { container: '#rollout-panel' }), 10)

      this.rolloutForm!.validate()
    }

    /*
    TODO: Add to the default group needed
    public async migrateFeatureRollout(target: string) {
      console.info('Migrating', target)

      const descriptions: any = {
        'sandbox': 'Created by Waltari for sandbox apps rollout.',
        'staging': 'Created by Waltari for staging apps rollout.',
        'release': 'Created by Waltari for release apps rollout.',
        'release-ios': 'Created by Waltari for release iOS app rollout.',
        'release-android': 'Created by Waltari for release Android app rollout.',
        'ouranians': 'Created by Waltari for Ouranians only rollout.',
        'experimental': 'Created by Waltari for experimental apps rollout.',
      }

      if (descriptions[target]) {
        this.activeOverride.metadata!.informative!.labels.rollout = target

        this.activeOverride.metadata!.informative!.labels.template = 'percentage-' + target

        this.activeOverride.metadata!.informative!.description = descriptions[target]

        this.activeOverride.metadata!.informative!.referenceUrls = [
          'https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management',
        ]

        await this.featuresStore.updateOverrides(this.feature, [this.activeOverride])

        this.$router.push(
          `/features/${getProjectKey(this.feature)}/${this.feature.metadata.name}/rollouts/${target.split('-')[0]}`,
        )
      }
    } */
  }

  export default toNative(RolloutEditor)
</script>

<style lang="scss" scoped>
  :deep(.uppercased) {
    input {
      text-transform: uppercase;
    }
  }
</style>
