// sort-imports-ignore

import { Node } from '@tiptap/core'
import { Editor } from '@tiptap/vue-3'

import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Italic } from '@tiptap/extension-italic'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Strike } from '@tiptap/extension-strike'
import { Text } from '@tiptap/extension-text'
import { Underline } from '@tiptap/extension-underline'

import { UIComponentRichText, UIComponentRichTextFormatPlaceholderMatch } from '#types'

export const blockNodes = [
  { title: 'Title S', value: 'title-s', command: 'toggleTitleS' },
  { title: 'Title M', value: 'title-m', command: 'toggleTitleM' },
  { title: 'Label M', value: 'label-m', command: 'toggleLabelM' },
  { title: 'Label L', value: 'label-l', command: 'toggleLabelL' },
  { title: 'Score L', value: 'score-l', command: 'toggleScoreL' },
  { title: 'Headline S', value: 'headline-s', command: 'toggleHeadlineS' },
  { title: 'Headline M', value: 'headline-m', command: 'toggleHeadlineS' },
  { title: 'Paragraph S', value: 'paragraph-s', command: 'toggleParagraphS' },
  { title: 'Paragraph M', value: 'paragraph-m', command: 'toggleParagraphM' },

  { title: 'Body (old)', value: 'body', command: 'toggleBodyOld' },
  { title: 'Heading (old)', value: 'heading', command: 'toggleHeadingOld' },
  { title: 'Heading Secondary (old)', value: 'heading-secondary', command: 'toggleHeadingSOld' },
  { title: 'Subtitle (old)', value: 'subtitle', command: 'toggleSubtitleOld' },
]

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    root: {
      setStyle: (style: string) => ReturnType
    }
  }
}

const Document = Node.create({
  name: 'doc',

  content: 'block',

  topNode: true,
})

const RootNode = Node.create({
  name: 'root',

  group: 'block',

  content: 'block+',

  defining: true,
  isolating: true,

  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => {
          return element.tagName.toLowerCase().replace('node-', '')
        },
      },
    }
  },

  parseHTML() {
    return blockNodes.map((n) => ({ tag: `node-${n.value}` }))
  },

  renderHTML(context: any): any {
    return [`node-${context.HTMLAttributes.style}`, {}, 0]
  },

  addCommands() {
    return {
      setStyle: (style: string) => (context: any) => {
        return context.commands.updateAttributes('root', { style })
      },
    }
  },
})

export function getNewEditor(
  data: UIComponentRichText,
  editable: boolean,
  updateData?: (text: string, placeholders: UIComponentRichTextFormatPlaceholderMatch[]) => void,
): Editor {
  return new Editor({
    editable,
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Strike,
      Underline,
      ListItem,
      BulletList,
      OrderedList,
      RootNode,
    ],
    content: data.contents.text,
    onUpdate: (context: any) => {
      if (updateData) {
        const text = context.editor.getHTML()

        updateData(text, getFormatPlaceholders(text, data.placeholders))
      }
    },
  })
}

// Returns the format placeholders `{n}` from the given text.
export function getFormatPlaceholders(
  text: string,
  existingPlaceholders: UIComponentRichTextFormatPlaceholderMatch[],
): UIComponentRichTextFormatPlaceholderMatch[] {
  const regex = /{(\d)}/gm
  const results: any[] = []

  let match: any = null

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text))) {
    const index = parseInt(match[1], 10)
    const existing = existingPlaceholders.find((p) => p.index === index)
    const statement = existing ? existing.statement : ''

    results.push({
      index,
      statement,
    })
  }

  return results
}
