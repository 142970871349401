<template>
  <div class="d-flex flex-shrink-1" style="min-width: 0">
    <v-menu location="bottom end" offset="20px" width="350" min-width="300" @click.stop>
      <template #activator="{ props }">
        <div v-bind="props" class="d-flex flex-row flex-shrink-1 mt-1" style="cursor: pointer; min-width: 0">
          <span class="text-truncate font-weight-light" style="min-width: 150px">
            {{ (activeProject && activeProject.name) || 'No project selected' }}
          </span>

          <v-icon class="mx-2">mdi-chevron-down</v-icon>
        </div>
      </template>

      <v-list>
        <v-list-item class="pa-0">
          <v-text-field
            v-model="filter"
            autofocus
            rounded="0"
            density="compact"
            placeholder="Filter projects"
            append-inner-icon="mdi-magnify"
            @click.stop
          />
        </v-list-item>

        <v-divider />

        <div style="max-height: 360px; overflow: auto">
          <v-hover
            v-for="projectItem in existingProjects.filter(
              (p) => !filter || p.name.toLowerCase().includes(filter.toLowerCase()),
            )"
            :key="projectItem.id"
          >
            <template #default="{ isHovering, props }">
              <v-list-item
                v-bind="props"
                class="pr-0"
                :title="projectItem.name"
                :class="{ 'text-primary': activeProject && activeProject.id === projectItem.id }"
                @click="selectProject(projectItem)"
              >
                <template v-if="canSeeStates" #prepend>
                  <v-icon
                    v-if="hasUnReleasedChanges(projectItem)"
                    class="my-n2"
                    :color="getStateColor(projectItem.state)"
                    icon="mdi-package-variant"
                  />
                  <v-icon v-else class="my-n2" :color="getStateColor(projectItem.state)" icon="mdi-circle-medium" />
                </template>

                <template v-if="isHovering || (activeProject && activeProject.id === projectItem.id)" #append>
                  <v-btn
                    v-if="hasEditRights(projectItem)"
                    class="my-n2"
                    color="primary"
                    icon="mdi-pencil"
                    style="cursor: pointer"
                    @click.stop="editProject(projectItem)"
                  />

                  <v-btn v-else class="my-n2" icon="mdi-eye" color="primary" @click.stop="editProject(projectItem)" />
                </template>
              </v-list-item>
            </template>
          </v-hover>
        </div>

        <v-divider />

        <v-list-item
          v-if="activeProject && activeProject.name"
          class="mt-2"
          title="Clear selected project"
          prepend-icon="mdi-close"
          @click="clearProject()"
        />
        <v-list-item title="Create new project" prepend-icon="mdi-plus" @click="newProject()" />
      </v-list>
    </v-menu>

    <ProjectEditor
      v-if="!!action"
      :open="!!action"
      :action="action"
      :project="selectedProject"
      @close="action = ''"
      @new-project="newProject('Duplicate')"
    />
  </div>
</template>

<script lang="ts">
  import { v4 as uuid } from 'uuid'

  import { cloneDeep } from 'lodash-es'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { projectStates } from '#views/projects/constants'

  import { AppStore, ProjectsStore } from '#stores'

  import { Project } from '#types'

  @Component({})
  class ProjectsMenu extends Vue {
    public action = ''
    public filter = ''

    public selectedProject: Project | null = null

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()

    public get userEmail() {
      return this.appStore.email ?? ''
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingProjects() {
      return this.projectsStore.projects.sort((p) => (p.id === this.activeProject?.id ? -1 : 1))
    }

    public get canSeeStates() {
      return this.appStore.isOTAContentAdmin
    }

    public newProject(action?: string) {
      this.action = action || 'Create'

      this.selectedProject = {
        id: uuid(),
        name: '',
        team: '',
        admins: [],
        owner: this.userEmail,
        sections: {
          contents: false,
          insights: false,
          features: false,
        },
        state: '',
      }
    }

    public clearProject() {
      this.projectsStore.setProject(null)
    }

    public editProject(project: Project) {
      this.action = this.hasEditRights(project) ? 'Edit' : 'View'

      this.selectedProject = cloneDeep(project)
    }

    public selectProject(project: Project) {
      if (this.activeProject?.id === project.id) {
        this.editProject(project)
      } else {
        this.projectsStore.setProject(project)
      }
    }

    public hasEditRights(project: Project) {
      return project.admins?.includes(this.userEmail) || this.userEmail === project.owner
    }

    public hasUnReleasedChanges(project: Project): boolean {
      const createdOrReleasedAt = project?.releasedAt || project?.createdAt
      if (!createdOrReleasedAt || !project.changesAt) {
        return false
      } else {
        return createdOrReleasedAt.toDate() < project.changesAt.toDate()
      }
    }

    public getStateColor(state: string) {
      return (projectStates.find((i) => i.title === state) || {}).color
    }
  }

  export default toNative(ProjectsMenu)
</script>
