<template>
  <v-toolbar
    v-if="rolloutView.startsWith('advanced')"
    class="d-flex flex-row align-end mb-n4"
    style="position: relative"
  >
    <v-toolbar-title class="px-3">
      {{
        override.metadata?.uid?.slice(0, -7).toUpperCase() ||
        'Unnamed ' + override.metadata?.informative?.labels.template.split('-')[0]
      }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn
      color="primary"
      text="Back to list"
      append-icon="mdi-arrow-right"
      @click="$router.push(`/features/${getProjectKey(feature)}/${feature.metadata?.name}/rollouts/advanced`)"
    />
  </v-toolbar>

  <v-toolbar class="d-flex flex-row align-end px-4" style="position: relative">
    <v-tabs
      v-if="rolloutView === 'sandbox'"
      :model-value="'sandbox'"
      @update:model-value="changeView($event as string)"
    >
      <v-tab text="Sandbox apps" value="sandbox" :prepend-icon="statusIcons[rolloutState('sandbox')]" />
    </v-tabs>

    <v-tabs
      v-else-if="rolloutView === 'staging' || rolloutView === 'experimental'"
      :model-value="rolloutView"
      @update:model-value="changeView($event as string)"
    >
      <v-tab text="Experimental app" value="experimental" :prepend-icon="statusIcons[rolloutState('experimental')]" />

      <v-tab text="Staging app" value="staging" :prepend-icon="statusIcons[rolloutState('staging')]" />
    </v-tabs>

    <v-tabs
      v-else-if="rolloutView.startsWith('release') || rolloutView === 'ouranians'"
      :model-value="rolloutView"
      @update:model-value="changeView($event as string)"
    >
      <v-tab text="Ouranians only" value="ouranians" :prepend-icon="statusIcons[rolloutState('ouranians')]" />

      <v-tab
        v-if="
          !(feature.metadata?.informative?.labels?.strategy || launchStrategy) ||
          (feature.metadata?.informative?.labels?.strategy || launchStrategy) === 'default'
        "
        text="Release app (All)"
        value="release"
        :prepend-icon="statusIcons[rolloutState('release')]"
      />

      <template v-else>
        <v-tab text="Release app (iOS)" value="release-ios" :prepend-icon="statusIcons[rolloutState('release-ios')]" />

        <v-tab
          text="Release app (Android)"
          value="release-android"
          :prepend-icon="statusIcons[rolloutState('release-android')]"
        />
      </template>
    </v-tabs>

    <template v-else-if="rolloutView.startsWith('advanced') && rolloutView !== 'advanced-listing' && !!override">
      <template v-if="overrides?.length === 1">
        <v-tabs
          class="mr-4"
          :model-value="'advanced-' + rolloutView.split('-')[1]"
          @update:model-value="changeView($event as string)"
        >
          <v-tab
            :text="
              rolloutTypes
                .find((e) => e.value === override.metadata?.informative?.labels.template)
                ?.title?.split('-')[1]
            "
            :prepend-icon="statusIcons[rolloutState(override)]"
            :value="'advanced-0'"
          />
        </v-tabs>
      </template>
      <template v-else>
        <v-tabs
          show-arrows
          center-active
          class="mr-4"
          :model-value="'advanced-' + rolloutView.split('-')[1]"
          @update:model-value="changeView($event as string)"
        >
          <v-tab
            v-for="(group, index) in overrides"
            :key="index"
            :text="group.metadata?.informative?.displayName || `Group ${index + 1}`"
            :prepend-icon="statusIcons[rolloutState(override)]"
            :value="'advanced-' + index"
          />
        </v-tabs>
      </template>
    </template>

    <v-spacer />

    <div
      v-if="
        rolloutView === 'release' &&
        rolloutState('ouranians') === 'INACTIVE' &&
        !feature.metadata?.informative?.labels?.strategy
      "
      class="font-weight-bold"
      style="min-width: 400px"
    >
      You need to launch Ouranians only rollout first!
    </div>
    <div
      v-else-if="rolloutView.startsWith('release') && !feature.metadata?.informative?.labels?.strategy"
      style="min-width: 400px"
    >
      <div class="d-flex flex-row align-center">
        <v-select
          v-model="launchStrategy"
          hide-details
          persistent-placeholder
          density="compact"
          style="min-width: 320px"
          label="Launch strategy (can not be changed later)"
          placeholder="Please select the launch strategy"
          :items="launchStrategies"
        />

        <v-btn class="ml-2" color="primary" text="Save" variant="flat" @click="saveStrategy()" />
      </div>
    </div>
    <template v-else>
      <v-menu
        v-if="!!override"
        v-model="timePickerMenu"
        location="bottom end"
        min-width="auto"
        transition="scale-transition"
        :disabled="
          previewOnly ||
          overrideRolloutStatus(override) === 'ACTIVE' ||
          overrideRolloutStatus(override) === 'SCHEDULED' ||
          overrideRolloutStatus(override) === 'FINALIZED'
        "
        :close-on-content-click="false"
      >
        <template #activator="{ props }">
          <v-text-field
            v-if="!!override"
            v-bind="props"
            readonly
            hide-details
            persistent-placeholder
            class="mr-4"
            density="compact"
            style="min-width: 230px; max-width: 230px"
            append-inner-icon="mdi-clock-time-four-outline"
            :placeholder="
              overrideRolloutStatus(override) === 'ACTIVE' || overrideRolloutStatus(override) === 'SCHEDULED'
                ? 'Pause rollout to edit'
                : 'Not set'
            "
            :label="'Launch time for ' + (rolloutView.startsWith('advanced') ? 'this rollout' : rolloutView)"
            :model-value="launchTimeText ? launchTimeText + $dayjs(launchDateText).format(' z') : ''"
          />
        </template>

        <v-time-picker format="24hr" :model-value="launchTimeText" @update:model-value="updateLaunchTime($event)" />
      </v-menu>

      <v-menu
        v-if="!!override"
        v-model="datePickerMenu"
        min-width="auto"
        location="bottom end"
        transition="scale-transition"
        :disabled="
          previewOnly ||
          overrideRolloutStatus(override) === 'ACTIVE' ||
          overrideRolloutStatus(override) === 'SCHEDULED' ||
          overrideRolloutStatus(override) === 'FINALIZED'
        "
        :close-on-content-click="false"
      >
        <template #activator="{ props }">
          <v-text-field
            v-bind="props"
            readonly
            hide-details
            persistent-placeholder
            density="compact"
            append-inner-icon="mdi-calendar"
            style="min-width: 230px; max-width: 230px"
            :placeholder="
              overrideRolloutStatus(override) === 'ACTIVE' || overrideRolloutStatus(override) === 'SCHEDULED'
                ? 'Pause rollout to edit'
                : 'Not set'
            "
            :clearable="
              !previewOnly &&
              overrideRolloutStatus(override) !== 'ACTIVE' &&
              overrideRolloutStatus(override) !== 'SCHEDULED' &&
              overrideRolloutStatus(override) !== 'FINALIZED'
            "
            :model-value="launchDateText ? $dayjs(launchDateText).format('DD MMM YYYY') : null"
            :label="'Launch date for ' + (rolloutView.startsWith('advanced') ? 'this rollout' : rolloutView)"
            @click:clear="clearLaunchDate()"
          />
        </template>

        <v-date-picker
          :model-value="launchDateText ? $dayjs(launchDateText).toDate() : undefined"
          @update:model-value="updateLaunchDate($event)"
        />
      </v-menu>
    </template>
  </v-toolbar>

  <template v-if="!!override">
    <v-alert v-if="invalidTime" closable type="info" class="ma-4" @click:close="invalidTime = false">
      Launch time was changed to earliest possible time, this is to ensure that the feature has had enough testing in
      previous rollout steps.
    </v-alert>

    <v-alert
      v-else-if="rolloutView === 'ouranians' && overrideRolloutStatus(feature, rolloutView) === 'INACTIVE'"
      closable
      type="info"
      class="ma-4"
    >
      You can limit Ouranians only rollout first to your team or individual users by editing the targeting criteria, no
      need to create a separate rollout as long as you don't need gradual rollout to Ouranians and remove the extra
      limitations and test with all Ouranians before starting the public rollout.
    </v-alert>
    <v-alert
      v-else-if="rolloutView.startsWith('release') && overrideRolloutStatus(feature, rolloutView) === 'INACTIVE'"
      closable
      type="warning"
      class="ma-4"
    >
      You should use this rollout only for the final gradual public rollout of the feature, for invisible rollouts and
      targeting only some individual release app users such as system testers or your team members you should always use
      the ouranians rollout or create an additional rollout from the advanced rollouts tab!
    </v-alert>
    <v-alert
      v-else-if="rolloutView.startsWith('advanced') && !override.metadata?.informative?.labels.rollout"
      closable
      type="info"
      class="ma-4"
    >
      Remember to disable custom rollouts when they have served their purpose e.g. when there is already a public
      rollout overriding the rollout, this will make managing and understanding the effect of the rollouts with their
      priorities easier.
    </v-alert>

    <v-alert
      v-if="
        !isLoading &&
        launchDateTime &&
        (rolloutView === 'ouranians' || rolloutView.startsWith('release')) &&
        overrideRolloutStatus(override) === 'INACTIVE' &&
        validateLaunchTime(launchTimeText) === true &&
        ($dayjs(launchDateTime).tz('Europe/Helsinki').hour() >= 14 ||
          [0, 5, 6].includes($dayjs(launchDateTime).tz('Europe/Helsinki').day()) ||
          $dayjs(launchDateTime).tz('America/Detroit').hour() >= 14 ||
          [0, 5, 6].includes($dayjs(launchDateTime).tz('America/Detroit').day()))
      "
      class="ma-4"
      type="warning"
    >
      <div>
        Risky launch time selected, to be safe use days from Monday to Thursday and pick time of day before 14:00. This
        way there is help available if there are any issues with the rollout.
      </div>

      <v-row class="mt-4">
        <v-col>
          <v-text-field
            readonly
            hide-details
            density="compact"
            variant="outlined"
            label="Local time"
            :model-value="$dayjs(launchDateTime).format('HH:mm ddd DD MMM YYYY z')"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            density="compact"
            variant="outlined"
            hide-details
            label="Detroit time"
            :model-value="$dayjs(launchDateTime).tz('America/Detroit').format('HH:mm ddd DD MMM YYYY z')"
            :error-messages="
              $dayjs(launchDateTime).tz('America/Detroit').hour() >= 14 ||
              [0, 5, 6].includes($dayjs(launchDateTime).tz('America/Detroit').day())
                ? ['']
                : []
            "
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            variant="outlined"
            density="compact"
            label="Helsinki time"
            :model-value="$dayjs(launchDateTime).tz('Europe/Helsinki').format('HH:mm ddd DD MMM YYYY z')"
            :error-messages="
              $dayjs(launchDateTime).tz('Europe/Helsinki').hour() >= 14 ||
              [0, 5, 6].includes($dayjs(launchDateTime).tz('Europe/Helsinki').day())
                ? ['']
                : []
            "
          />
        </v-col>
      </v-row>
    </v-alert>
  </template>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { launchStrategies, rolloutTypes, statusIcons } from '#views/features/constants'

  import { featureOverrideState, findFeatureOverride, overrideRolloutStatus } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { AppStore, FeaturesStore } from '#stores'

  import { Feature, Override, Reference, RolloutTarget } from '#types'

  @Component({})
  class StatusConfig extends Vue {
    @Prop() public feature!: Feature

    @Prop() public override!: Override
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Prop() public previewOnly!: boolean

    @Prop() public rolloutView!: RolloutTarget

    public invalidTime = false
    public datePickerMenu = false
    public timePickerMenu = false

    public launchStrategy: string | null = null

    public readonly getProjectKey = getProjectKey
    public readonly overrideRolloutStatus = overrideRolloutStatus

    public readonly statusIcons = statusIcons
    public readonly rolloutTypes = rolloutTypes
    public readonly launchStrategies = launchStrategies

    private readonly appStore = new AppStore()

    private readonly featuresStore = new FeaturesStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get launchDateTime() {
      return this.launchDateText && this.launchTimeText ? this.launchDateText + ' ' + this.launchTimeText : null
    }

    public get launchTimeText() {
      const date = this.override?.metadata?.informative?.additionalData?.launchAt

      return date ? this.$dayjs(date).format('HH:mm') : null
    }

    public get launchDateText() {
      const date = this.override?.metadata?.informative?.additionalData?.launchAt

      return date ? this.$dayjs(date).format('YYYY-MM-DD') : null
    }

    @Watch('launchStrategy')
    protected launchStrategyChanged() {
      this.$nextTick(() => {
        if (this.launchStrategy === 'default') {
          this.$router.push(`/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/release`)
        } else if (this.launchStrategy === 'advanced') {
          this.$router.push(
            `/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/release-ios`,
          )
        }
      })
    }

    public async saveStrategy() {
      this.feature.metadata!.informative!.labels.strategy = this.launchStrategy || ''

      await this.featuresStore.updateFeature(this.feature!)

      this.launchStrategy = ''
    }

    public changeView(target: string) {
      if (!target.startsWith('advanced')) {
        this.$router.push(`/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/${target}`)
      } else {
        this.$router.push(
          `/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/advanced/${
            this.override.metadata?.informative?.additionalData?.createdAt ? this.override.metadata?.uid : 'create'
          }/${target.split('-')[1]}`,
        )
      }
    }

    public rolloutState(target: string | Override) {
      const override = typeof target === 'string' ? findFeatureOverride(this.feature, target) : (target as Override)

      if (!override) {
        return 'INACTIVE'
      } else if (featureOverrideState(override) === 'DISABLED') {
        return 'DISABLED'
      } else {
        const status = overrideRolloutStatus(override)

        return status === 'INACTIVE' ? (this.rolloutPrepared(override) ? 'PREPARED' : status) : status
      }
    }

    public rolloutPrepared(override: Override) {
      const launchTime = override?.metadata?.informative?.additionalData?.launchAt

      return launchTime && this.$dayjs(launchTime) >= this.$dayjs().subtract(15, 'minute')
    }

    public clearLaunchDate() {
      this.overrides.forEach((override) => delete override?.metadata?.informative?.additionalData?.launchAt)

      this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)
    }

    public updateLaunchDate(date: Date | string) {
      this.datePickerMenu = false

      const dateString = this.$dayjs(date).format('YYYY-MM-DD')
      const timeString = this.launchTimeText ?? this.$dayjs().add(1, 'hour').format('HH:00')

      this.override!.metadata!.informative!.additionalData!.launchAt = this.$dayjs(
        `${dateString} ${timeString}`,
      ).toISOString()

      this.correctLaunchDateAndTime()
    }

    public updateLaunchTime(time: string) {
      if (this.validateLaunchTime(time) === true) {
        const timeString = time ?? '00:00'
        const dateString = this.launchDateText ?? this.$dayjs().format('YYYY-MM-DD')

        this.override!.metadata!.informative!.additionalData!.launchAt = this.$dayjs(
          `${dateString} ${timeString}`,
        ).toISOString()

        this.correctLaunchDateAndTime()
      }
    }

    public validateLaunchTime(time: string | null) {
      return time === null || this.$dayjs(time, 'HH:mm', true).isValid() || 'Needs to be in HH:mm format!'
    }

    private correctLaunchDateAndTime() {
      const dateString = this.launchDateText
      const timeString = this.launchTimeText

      if (this.rolloutView === 'sandbox') {
        const schedule = this.$dayjs(`${dateString} ${timeString}`)

        const earliest = this.$dayjs()

        if (schedule < earliest) {
          this.override!.metadata!.informative!.additionalData!.launchAt = earliest.toISOString()
        }
      } else if (this.rolloutView === 'staging' || this.rolloutView === 'experimental') {
        let earliest

        const schedule = this.$dayjs(`${dateString} ${timeString}`)

        if (this.rolloutView === 'experimental') {
          earliest = this.$dayjs(
            findFeatureOverride(this.feature, 'sandbox')?.metadata?.informative?.additionalData?.launchAt,
          )
        } else {
          earliest =
            this.$dayjs(
              findFeatureOverride(this.feature, 'experimental')?.metadata?.informative?.additionalData?.launchAt,
            ) ||
            this.$dayjs(findFeatureOverride(this.feature, 'sandbox')?.metadata?.informative?.additionalData?.launchAt)
        }

        if (schedule < earliest && !this.isWaltariAdmin) {
          this.invalidTime = true

          this.override!.metadata!.informative!.additionalData!.launchAt = earliest.toISOString()
        }
      } else if (this.rolloutView.startsWith('release') || this.rolloutView === 'ouranians') {
        let earliest

        const schedule = this.$dayjs(`${dateString} ${timeString}`)

        if (this.rolloutView === 'ouranians') {
          earliest = this.$dayjs(
            findFeatureOverride(this.feature, 'staging')?.metadata?.informative?.additionalData?.launchAt,
          ).add(1, 'day')
        } else {
          earliest = this.$dayjs(
            findFeatureOverride(this.feature, 'ouranians')?.metadata?.informative?.additionalData?.launchAt,
          ).add(1, 'day')
        }

        if (schedule < earliest && !this.isWaltariAdmin) {
          this.invalidTime = true

          this.override!.metadata!.informative!.additionalData!.launchAt = earliest.toISOString()
        }
      }

      const overrides = this.overrides.concat(...this.references.map((r: any) => r.overrides))

      overrides.forEach(
        (override) =>
          (override!.metadata!.informative!.additionalData!.launchAt =
            this.override!.metadata!.informative!.additionalData!.launchAt),
      )

      this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)
    }
  }

  export default toNative(StatusConfig)
</script>
