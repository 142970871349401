<template>
  <v-dialog v-if="$featureEnabled('daphneIntegration')" v-model="isOpen" max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="mr-2">Generate Messages</span>

        <v-spacer />

        <v-btn icon="mdi-arrow-left" class="mt-n2" @click="previousMessage()" />
        <span class="mx-2">{{ messageIndex() + 1 }} / {{ messages.length }}</span>
        <v-btn icon="mdi-arrow-right" class="mt-n2" @click="nextMessage()" />

        <v-spacer />

        <v-btn color="primary" icon="mdi-information-outline" @click="infoDialog = true" />
        <v-btn :color="isInsightsEditor ? 'primary' : 'secondary'" icon="mdi-cog" @click="openSettingsDialog()" />
      </v-card-title>
      <v-card-text>
        <v-row cols="12">
          <v-col cols="6">
            <v-row>
              <v-col>
                <div class="text-pre-wrap">
                  <div class="mx-2" style="max-height: 480px; min-height: 320px; overflow: auto">
                    <MessageItem class="message pa-3" :insight="insight" :message="message" />
                  </div>
                </div>
              </v-col>
              <v-col>
                <v-textarea
                  rows="11"
                  label="Additional prompt (optional)"
                  :model-value="additionalPrompt"
                  auto-grow
                  @update:model-value="additionalPrompt = $event"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row dense>
              <v-col>
                <v-textarea
                  rows="11"
                  :model-value="newMessage"
                  :disabled="generating"
                  label="Generated message"
                  auto-grow
                  @update:model-value="newMessage = $event"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-alert v-if="generating" variant="text" type="info" text="Generation in progress..." />
                <v-alert v-if="errorMessage" variant="text" type="error" :text="errorMessage" />
                <v-alert
                  v-if="!isValidBaseMessage"
                  variant="text"
                  type="warning"
                  text="The selected message is of wrong format for variant generation"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="caption">WARNING: This feature is still experimental</div>

        <v-spacer />
        <v-btn color="primary" text="Generate" :disabled="generating" @click="generateVariant()" />
        <v-btn color="primary" text="Cancel" :disabled="saving" @click="onCancelClicked()" />
        <v-btn
          color="primary"
          text="Save"
          :loading="saving"
          :disabled="!newMessage && isValidBaseMessage"
          @click="onOkClicked()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="infoDialog" max-width="1200px">
    <v-card>
      <v-card-title class="headline">Additional information</v-card-title>
      <v-card-text>
        <span>Has been generated: {{ 'generated' in message }}</span>
        <br />
        <span>Used additional prompt: {{ message.usedPrompt ?? 'None' }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text="Close" @click="infoDialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DaphneSettingsDialog ref="daphneSettingsDialog" />
</template>

<script lang="ts">
  import { Component, Emit, Vue, toNative } from 'vue-facing-decorator'

  import { getApp } from 'firebase/app'
  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { AppStore } from '#stores'

  import { Dialog, Insight, Message } from '#types'

  @Component({})
  class GenerateMessagesDialog extends Vue {
    @Emit('generateMessage')
    public generateMessage(props: { text: string; message: Message; usedPrompt: string | null }) {
      return props
    }

    @Emit('generateDialogSave')
    public async onOkClicked() {
      this.saving = true

      this.newMessage
        ? this.generateMessage({ text: this.newMessage, message: this.message, usedPrompt: this.usedAdditionalPrompt })
        : console.error('Cant generate new message without text')

      this.isOpen = false
      this.saving = false
      return false
    }

    @Emit('generateDialogCancel')
    public onCancelClicked() {
      this.isOpen = false
      return false
    }

    public isOpen = false
    public saving = false
    public generating = false
    public infoDialog = false

    public get isValidBaseMessage() {
      //We need atleast one rich_text element to generate a variant
      return (this.message.facets.view?.components ?? []).filter((c: any) => c.type === 'rich_text').length > 0
    }

    public declare $refs: {
      daphneSettingsDialog: Dialog
    }

    private readonly appStore = new AppStore()

    public newMessage = ''
    public additionalPrompt = ''
    public usedAdditionalPrompt: string | null = null
    public errorMessage = ''

    public insight: Insight | null = null
    public message!: Message
    public messages!: Message[]

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public async openSettingsDialog() {
      this.$refs.daphneSettingsDialog.open()
    }

    public messageIndex() {
      return this.messages.findIndex((m) => m.id === this.message.id) ?? 0
    }

    public nextMessage() {
      const index = this.messageIndex()
      this.message = this.messages[(index + 1) % this.messages.length]
    }

    public previousMessage() {
      const index = this.messageIndex()
      this.message = this.messages[(index - 1 + this.messages.length) % this.messages.length]
    }

    @Debounce(200)
    public async generateVariant() {
      this.generating = true
      this.errorMessage = ''
      const paragraphs = this.message.facets.view?.components
        .filter((c: any) => c.type === 'rich_text')
        .map((c: any) => c.contents.text.replace(/<(.|\n)*?>/g, ''))
      const prompt = paragraphs?.join('\n\n')

      const messages = [{ role: 'user', content: prompt }]

      if (this.additionalPrompt) {
        this.usedAdditionalPrompt = this.additionalPrompt
        messages.splice(1, 0, { role: 'system', content: `Some additional guidance to obey: ${this.additionalPrompt}` })
      }

      const functions = getFunctions(getApp(), 'europe-west1')
      try {
        const response: any = await httpsCallable(functions, 'fetchCompletionsFromDaphne', { timeout: 100000 })({
          messages,
        })
        this.newMessage = response.data.newMessage
      } catch (error: any) {
        console.log(error)
        this.errorMessage = error.message
      }

      this.generating = false
    }

    public async open(insight: Insight, message: Message, messages: Message[]) {
      this.message = message
      this.messages = messages
      this.newMessage = ''
      this.insight = insight
      this.isOpen = true
    }
  }

  export default toNative(GenerateMessagesDialog)
</script>
