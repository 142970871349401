import { v4 as uuid } from 'uuid'

import { pick } from 'lodash-es'

import { Timestamp, doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

import { messageStates } from '#views/insights/constants'

import { setIds } from '#views/insights/utilities'

import { createUIComponentDefaultData } from '#views/messages/components/utilities'

import { insightsMigrationMap, messagesMigrationMap } from '#views/insights/migrations'

import { AppStore } from '#stores'

import {
  ContentOrigin,
  CreateMessageProps,
  LinksItemType,
  MediaItemType,
  Message,
  MessageHomeFacet,
  MessageHomeFacetAction,
  MessageNotificationFacet,
  MessageViewFacet,
  Translatable,
  UIComponentType,
} from '#types'

import { nanoId, prefixedId } from '#utilities'

export function createDefaultMessage(prefix: string, origin?: ContentOrigin, order?: number): Message {
  // TODO: This is temporal to keep ids already correct until we do the migration in apps
  const migratedId = insightsMigrationMap[prefix.split(':')[0]] || prefix.split(':')[0]

  prefix = prefix.replace(prefix.split(':')[0], migratedId)

  return {
    id: prefixedId(prefix),
    facets: {
      home: null,
      view: null,
      notification: null,
    },
    state: 'draft',
    order: order ?? 0,
    origin: origin ?? 'insight',
  }
}

export async function duplicateExistingMessage(
  collection: string,
  message: Message,
  insightId: string,
  messageOrder: number,
) {
  const appStore = new AppStore()

  const author: any = pick(appStore.user, ['displayName', 'photoURL', 'email', 'uid'])

  const newMessage = createDefaultMessage(insightId, 'insight', messageOrder)

  const messageCopy = setIds(JSON.parse(JSON.stringify(message)), message.id, newMessage.id)

  const data = {
    ...messageCopy,
    author,
    state: 'draft',
    id: newMessage.id,
    order: newMessage.order,
  }

  await setDoc(doc(getFirestore(), `${collection}/${insightId}/messages/${data.id}`), data)

  return data.id
}

export async function generateNewVariant(
  newText: string,
  insightId: string,
  message: Message,
  messageCount: number,
  usedPrompt: string | null,
) {
  const appStore = new AppStore()
  const author: any = pick(appStore.user, ['displayName', 'photoURL', 'email', 'uid'])
  const defaultMessage = createDefaultMessage(insightId, 'insight', messageCount)

  const messageCopy = setIds(JSON.parse(JSON.stringify(message)), message.id, defaultMessage.id)

  const data = {
    ...messageCopy,
    author,
    generated: true,
    state: 'draft',
    id: messageCopy.id,
    order: messageCopy.order,
    usedPrompt,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  const textIndexes = data.facets.view!.components.reduce((indexes: number[], c: any, i: number) => {
    if (c.type === UIComponentType.richText) {
      indexes.push(i)
    }
    return indexes
  }, [])

  const paragraphs = newText.split('\n\n')

  /**
   * T - Rich tex component
   * G - Any other component
   * When generating variants we want to
   * A) replace in-place if there are equally many new 'paragraphs' in the original and new message
   * eg. TGTG -> TGTG
   * B) replace in-place until, we run out of new paragraphs and discard the rest of the old ones
   * eg. TGTG -> TGG
   * C) replace in-place and fill all remaining ones after the last paragraph replaced
   * eg. TGTG -> TGTTG
   */

  if (paragraphs.length === textIndexes.length) {
    paragraphs.forEach((paragraph) => {
      const i = textIndexes.shift()
      const template = createUIComponentDefaultData(UIComponentType.richText, `${messageCopy.id}:view`)

      ;(template as any).contents.text = `<node-paragraph-m><p>${paragraph}</p></node-paragraph-m>`
      data.facets.view!.components[i] = template
    })
  } else if (paragraphs.length > textIndexes.length) {
    let lastText = textIndexes[textIndexes.length - 1]
    paragraphs.forEach((paragraph) => {
      const i = textIndexes.shift()
      const template = createUIComponentDefaultData(UIComponentType.richText, `${messageCopy.id}:view`)

      ;(template as any).contents.text = `<node-paragraph-m><p>${paragraph}</p></node-paragraph-m>`
      if (i !== undefined) {
        data.facets.view!.components[i] = template
      } else {
        data.facets.view!.components.splice(++lastText, 0, template)
      }
    })
  } else {
    // paragraphs.length < textIndexes.length
    let i = 0
    paragraphs.forEach((paragraph) => {
      i = textIndexes.shift()
      const template = createUIComponentDefaultData(UIComponentType.richText, `${messageCopy.id}:view`)

      ;(template as any).contents.text = `<node-paragraph-m><p>${paragraph}</p></node-paragraph-m>`
      data.facets.view!.components[i] = template
    })
    data.facets.view!.components = data.facets.view!.components.filter(
      (c: any, j: number) => j <= i || c.type !== UIComponentType.richText,
    )
  }

  await setDoc(doc(getFirestore(), `insights/${insightId}/messages/${data.id}`), data)
  return data.id
}

export function getOrigin(path: string): ContentOrigin {
  return path.includes('users') ? 'personal' : 'insight'
}

export function createViewFacet(_messageId?: string): MessageViewFacet {
  return {
    components: [],
  }
}

export function createNotificationFacet(messageId: string): MessageNotificationFacet {
  // TODO: This is temporal to keep translation ids already correct until we do the migration in apps
  const migratedId = messagesMigrationMap[messageId.split(':')[0]] || messageId.split(':')[0]

  messageId = messageId.replace(messageId.split(':')[0], migratedId)

  return {
    body: createDefaultTranslatable(`${messageId}:notification`, 'notification body'),
    title: createDefaultTranslatable(`${messageId}:notification`, 'notification title'),
  }
}

export function createHomeFacetAction(messageId: string): MessageHomeFacetAction {
  // TODO: This is temporal to keep translation ids already correct until we do the migration in apps
  const migratedId = messagesMigrationMap[messageId.split(':')[0]] || messageId.split(':')[0]

  messageId = messageId.replace(messageId.split(':')[0], migratedId)

  return {
    type: 'cta_primary',
    analytics_id: null,
    condition: null,
    id: uuid(),
    title: createDefaultTranslatable(`${messageId}:home`, 'button title'),
  }
}

export function createLinksObjectItem(messageId: string): LinksItemType {
  // TODO: This is temporal to keep translation ids already correct until we do the migration in apps
  const migratedId = messagesMigrationMap[messageId.split(':')[0]] || messageId.split(':')[0]

  messageId = messageId.replace(messageId.split(':')[0], migratedId)

  return {
    id: nanoId(),
    type: 'web',
    title: createDefaultTranslatable(`${messageId}:link`, 'link title'),
    value: '',
  }
}

export function createMediaObjectItem(): MediaItemType {
  return {
    slug: '',
    type: 'video',
    duration: 0,
    id: nanoId(),
  }
}

export function createHomeFacet(messageId: string): MessageHomeFacet {
  // TODO: This is temporal to keep translation ids already correct until we do the migration in apps
  const migratedId = messagesMigrationMap[messageId.split(':')[0]] || messageId.split(':')[0]

  messageId = messageId.replace(messageId.split(':')[0], migratedId)

  return {
    body: createDefaultTranslatable(`${messageId}:home`, 'body'),
    title: createDefaultTranslatable(`${messageId}:home`, 'title'),
    header: createDefaultTranslatable(`${messageId}:home`, 'header'),
    actions: [],
    display: 'action_card',
    components: [],
  }
}

export function createDefaultTranslatable(prefix: string, comment: string): Translatable {
  // TODO: This is temporal to keep translation ids already correct until we do the migration in apps
  const migratedId = messagesMigrationMap[prefix.split(':')[0]] || prefix.split(':')[0]

  prefix = prefix.replace(prefix.split(':')[0], migratedId)

  return {
    text: '',
    translationId: prefixedId(prefix),
    comment,
  }
}

export async function createMessage({
  template,
  type,
  messages,
  insightsPath,
  insight,
  page,
  insightPath,
}: CreateMessageProps) {
  const defaultMessage = createDefaultMessage(insight!.id, getOrigin(insightsPath), messages.length)

  let data = {
    ...defaultMessage,
    updatedAt: serverTimestamp() as Timestamp,
    createdAt: serverTimestamp() as Timestamp,
  }

  if (template) {
    const author = new AppStore().author

    let view = template.facets.view
    if (template.facets.view) {
      const components = template.facets.view.components.map((c: any) => {
        Object.keys(c).forEach((key) => {
          if (typeof c[key] == 'object' && c[key].translationId) {
            c[key].translationId = createDefaultTranslatable(`${defaultMessage.id}:view`, c.type).translationId
          }
        })
        return {
          ...c,
          id: c.id.replace(insight!.id, defaultMessage.id),
        }
      })

      view = { ...view, components }
    }

    if (template.info) {
      data = {
        ...data,
        info: template.info,
      }
    }

    data = {
      ...data,
      author: author,
      template: template.id,
      facets: { home: null, notification: null, view },
    }
  }

  if (type && page?.toString()) {
    data = { ...data, type: type.toLowerCase(), page: page }
  }

  await setDoc(doc(getFirestore(), `${insightPath}/messages`, data.id), data)

  return data
}

export async function generateNewMessage(
  title: string,
  text: string,
  insightId: string,
  usedPrompt: string | null,
  messageOrder: number,
) {
  const appStore = new AppStore()
  const author: any = pick(appStore.user, ['displayName', 'photoURL', 'email', 'uid'])
  const defaultMessage = createDefaultMessage(insightId, 'insight', messageOrder)

  const data = {
    ...defaultMessage,
    facets: {
      view: {
        components: [] as any[],
      },
    },
    author,
    generated: true,
    state: 'draft',
    id: defaultMessage.id,
    order: defaultMessage.order,
    usedPrompt,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  const titleElement = createUIComponentDefaultData('rich_text' as UIComponentType, `${defaultMessage.id}:view`)

  ;(titleElement as any).contents.text = `<node-paragraph-m><p>${title}</p></node-paragraph-m>`

  data.facets.view.components.push(titleElement)

  text.split('\n').forEach((paragraph: string) => {
    const template = createUIComponentDefaultData('rich_text' as UIComponentType, `${defaultMessage.id}:view`)

    ;(template as any).contents.text = `<node-paragraph-m><p>${paragraph}</p></node-paragraph-m>`
    data.facets.view.components.push(template)
  })

  await setDoc(doc(getFirestore(), `insights/${insightId}/messages/${data.id}`), data)
  return data.id
}

export const messageColor = (message: Message): { backgroundColor: string; textColor: string; borderColor: string } => {
  const backgroundColor = messageStates.find((state) => state.value === message.state)?.color || 'black'
  const textColor = messageStates.find((state) => state.value === message.state)?.textColor || 'white'
  const borderColor = message.generated ? 'red' : backgroundColor

  return { backgroundColor, textColor, borderColor }
}
