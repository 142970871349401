import { v4 as uuid } from 'uuid'

import { AppStore, InsightsStore } from '#stores'

import { ContentOrigin, Insight, InsightChartData, InsightStateType, Message } from '#types'

import { prefixedId } from '#utilities'

export function setIds(obj: any, oldId: string, newId: string) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null) {
      if (typeof obj[key] === 'object') {
        obj[key] = setIds(obj[key], oldId, newId)
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(oldId, newId)
      }
    }
  })

  return obj
}

function processObject(obj: Insight | Message, newValues: {}) {
  return {
    ...obj,
    ...newValues,
    originalId: obj.id,
    originalAuthor: obj.author,
    author: new AppStore().author,
  } as Insight | Message
}

function processInsight(insight: Insight, id: string, state: string) {
  return processObject(insight, { id, state }) as Insight
}

function processMessage(message: Message, id: string, order: number) {
  const messageCopy = setIds(JSON.parse(JSON.stringify(message)), message.id, id)

  return processObject(messageCopy, { id, order }) as Message
}

export async function copyInsightToWorkspace(insight: Insight, messages: Message[]) {
  const uuid = new AppStore().uuid
  if (!uuid) {
    return
  }

  const insigthsStore = new InsightsStore()
  const currentInsights = await insigthsStore.getUserInsights()

  // Check insight with same id and create a new id for workspace
  const re = new RegExp(`${insight.id}(:\\d*|$)`)
  const count = currentInsights.docs.filter((doc) => re.test(doc.id)).length
  const insightId = count === 0 ? insight.id : `${insight.id}:${count}`
  const processedInsight = processInsight(insight, insightId, 'created')
  const processedMessages = messages.map((message, index) =>
    processMessage(message, prefixedId(message.id.split(':')[0]), index),
  )

  insigthsStore.batchSetMessages('users', uuid, processedMessages, processedInsight)
}

export async function copyInsightFromWorkspace(insight: Insight, messages: Message[], id: string) {
  const insigthsStore = new InsightsStore()

  const collection = insight.category === 'slideshow' ? 'slideshows' : 'insights'
  const processedInsight = processInsight(insight, id, 'draft')
  const processedMessages = messages.map((message, index) =>
    processMessage(message, prefixedId(message.id.split(':')[0]), index),
  )

  insigthsStore.batchSetMessages(collection, processedInsight.id, processedMessages, processedInsight)
}

export async function copyMessagesFromWorkspace(insight: Insight, messages: Message[], order: number) {
  const insigthsStore = new InsightsStore()

  const collection = insight.category === 'slideshow' ? 'slideshows' : 'insights'
  const processedMessages = messages.map((message) => {
    const processedMessage = processMessage(message, prefixedId(message.id.split(':')[0]), order)
    order++

    return processedMessage
  })

  insigthsStore.batchSetMessages(collection, insight.id, processedMessages)
}

export function createDefaultInsight(state?: InsightStateType, origin?: ContentOrigin): Insight {
  return {
    id: uuid(),
    state: state ?? 'draft',
    rule: '',
    rules: [],
    priority: '0',
    group_id: '',
    description: '',
    category: '',
    minimum_interval: 3,
    minimum_category_interval: 0,
    silent: true,
    transient: false,
    tags: [],
    tips: [],
    relations_tips_limit: 1,
    origin: origin ?? 'insight',
  }
}

export function calculateRatio(a: number, b: number): number {
  return a === 0 ? 0 : b === 0 ? 100 : Number(((a / b) * 100).toFixed(2))
}

export const convertInsightStatisticsToChartData = (
  chartStats: {
    date: string
    viewed: {
      total: number
      byApp: { android: number; ios: number }
    }
    clicked: { total: number; byApp: { android: number; ios: number } }
    generated: { total: number; byApp: { android: number; ios: number } }
  }[],
) => {
  return chartStats
    .reduce((chartData, statistic) => {
      chartData.push({
        'date': statistic.date,
        'viewed': statistic.viewed.total,
        'clicked': statistic.clicked.total,
        'generated': statistic.generated.total,
        'android viewed': statistic.viewed.byApp.android,
        'android clicked': statistic.clicked.byApp.android,
        'android generated': statistic.generated.byApp.android,
        'ios viewed': statistic.viewed.byApp.ios,
        'ios clicked': statistic.clicked.byApp.ios,
        'ios generated': statistic.generated.byApp.ios,
      })

      return chartData
    }, [] as InsightChartData[])
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
}
