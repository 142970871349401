<template>
  <v-footer
    v-if="isProjectEditor(feature)"
    order="1"
    class="d-flex flex-row flex-shrink-1 py-4"
    style="border-top: 1px solid rgba(0, 0, 0, 0.2)"
  >
    <v-alert v-if="errorMessage" tile closable color="error" @click:close="errorMessage = null">
      <v-row align="center">
        <v-col class="grow">
          {{ errorMessage }}
        </v-col>
      </v-row>
    </v-alert>

    <template v-else>
      <template v-if="rolloutView.startsWith('advanced')">
        <v-btn
          v-if="isWaltariAdmin || overrideRolloutStatus(override) === 'INACTIVE'"
          color="error"
          text="Delete this rollout"
          prepend-icon="mdi-delete-outline"
          :disabled="isLoading"
          @click="deleteRollout()"
        />
        <template v-if="!!override && overrideRolloutStatus(override) !== 'INACTIVE'">
          <v-btn
            v-if="overrideRolloutStatus(override) !== 'DISABLED'"
            color="error"
            text="Disable this rollout"
            prepend-icon="mdi-close-outline"
            :disabled="isLoading"
            @click="disableRollout()"
          />
          <v-btn
            v-else
            color="success"
            :disabled="isLoading"
            text="Enable this rollout"
            prepend-icon="mdi-rocket-launch-outline"
            @click="enableRollout()"
          />
        </template>
      </template>
      <template v-else>
        <v-btn
          v-if="!override || featureOverrideState(override, true) !== 'DISABLED'"
          color="error"
          prepend-icon="mdi-close-outline"
          :text="`Disable for ${rolloutView.replace('release-', '')}`"
          :disabled="isLoading"
          @click="disableFeature()"
        />
        <v-btn
          v-else
          color="success"
          prepend-icon="mdi-check-outline"
          :disabled="isLoading"
          :text="`Enable for ${rolloutView.replace('release-', '')}`"
          @click="enableFeature()"
        />
      </template>

      <v-spacer />

      <v-tooltip
        v-if="!!override"
        location="top center"
        :disabled="
          overrideRolloutStatus(override) !== 'ACTIVE' &&
          overrideRolloutStatus(override) !== 'SCHEDULED' &&
          (!rolloutView.startsWith('release') || !!feature.metadata?.informative?.labels?.strategy)
        "
        :text="
          overrideRolloutStatus(override) === 'ACTIVE' || overrideRolloutStatus(override) === 'SCHEDULED'
            ? 'Pause rollout to edit'
            : 'Set the strategy to edit'
        "
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              class="mr-4"
              color="info"
              prepend-icon="mdi-pencil"
              :text="`Edit ${actionTarget} rollout`"
              :disabled="
                isLoading ||
                overrideRolloutStatus(override) === 'ACTIVE' ||
                overrideRolloutStatus(override) === 'SCHEDULED' ||
                (rolloutView.startsWith('release') && !feature.metadata?.informative?.labels?.strategy)
              "
              @click="openEditPanel()"
            />
          </div>
        </template>
      </v-tooltip>

      <v-btn
        v-if="
          !!override &&
          (overrideRolloutStatus(override) === 'ACTIVE' || overrideRolloutStatus(override) === 'SCHEDULED')
        "
        color="warning"
        prepend-icon="mdi-pause"
        :disabled="isLoading"
        :text="`Pause ${actionTarget} rollout`"
        @click="pauseRollout()"
      />

      <v-tooltip
        v-else-if="!!override && overrideRolloutStatus(override) === 'PAUSED'"
        location="top center"
        :disabled="rolloutPrepared"
        :text="!rolloutPrepared ? 'Set a new launch time to continue' : undefined"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="success"
              prepend-icon="mdi-play"
              :disabled="isLoading || !hasFutureStage || !rolloutPrepared"
              :text="`Continue ${actionTarget} rollout`"
              @click="unpauseRollout()"
            />
          </div>
        </template>
      </v-tooltip>

      <v-tooltip
        v-else-if="!!override && overrideRolloutStatus(override) !== 'FINALIZED'"
        location="top center"
        :disabled="rolloutPrepared"
        :text="!rolloutPrepared ? 'Set valid launch time first' : ''"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="success"
              prepend-icon="mdi-rocket-launch-outline"
              :text="`Launch ${actionTarget} rollout`"
              :disabled="isLoading || !rolloutPrepared || overrideRolloutStatus(override) === 'DISABLED'"
              @click="launchRollout()"
            />
          </div>
        </template>
      </v-tooltip>
    </template>
  </v-footer>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Override_State } from '@jouzen/control-api/override'

  import { featureOverrideState, hasFutureRolloutStages, overrideRolloutStatus } from '#views/features/utilities'
  import { getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore } from '#stores'

  import { Feature, Override, Reference, RolloutTarget } from '#types'

  @Component({})
  class StatusAlerts extends Vue {
    @Prop() public feature!: Feature

    @Prop() public override!: Override
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Prop() public rolloutView!: RolloutTarget

    public readonly isProjectEditor = isProjectEditor
    public readonly featureOverrideState = featureOverrideState
    public readonly overrideRolloutStatus = overrideRolloutStatus

    private readonly appStore = new AppStore()
    private readonly featuresStore = new FeaturesStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get errorMessage() {
      return this.featuresStore.errorMessage
    }

    public set errorMessage(msg: string | null) {
      this.featuresStore.errorMessage = msg
    }

    public get actionTarget() {
      return !this.rolloutView.startsWith('advanced') ? this.rolloutView.replace('release-', '') : 'this'
    }

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get hasFutureStage() {
      return hasFutureRolloutStages(this.overrides)
    }

    public get rolloutPrepared() {
      return this.override?.metadata?.informative?.additionalData?.launchAt
        ? this.$dayjs(this.override?.metadata?.informative?.additionalData?.launchAt) >=
            this.$dayjs().subtract(15, 'minute')
        : false
    }

    @Watch('feature')
    public featureChange() {
      this.errorMessage = null
    }

    public openEditPanel() {
      this.appStore.toggleNavDrawer('rollout')
    }

    public async launchRollout() {
      this.$confirm(
        `Launch this rollout?`,
        `This will activate the first uncompleted rollout step on the set launch time for the users matching the targeting criteria / segments.`,
        {
          buttonTrueColor: 'success',
        },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Launching', this.overrides)

          this.overrides.forEach((override) => delete override.metadata?.informative!.additionalData!.pausedAt)

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.launchOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }

    public async pauseRollout() {
      this.$confirm(
        `Pause this rollout?`,
        `Currently active step will remain active, but the rollout wont advance to the next step without manual continue.`,
        {
          buttonTrueColor: 'warning',
        },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Pausing', this.overrides)

          const dateNow = this.$dayjs().toISOString()

          this.overrides.forEach((override) => delete override.metadata?.informative!.additionalData!.resumeAt)

          this.overrides.forEach((override) => (override.metadata!.informative!.additionalData!.pausedAt = dateNow))

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.pauseOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }

    public async unpauseRollout() {
      this.$confirm(
        `Continue this rollout?`,
        `Currently active step will complete and the rollout advances to the next cnofigured step on the set launch time.`,
        {
          buttonTrueColor: 'success',
        },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Unpausing', this.overrides)

          this.overrides.forEach((override) => delete override.metadata?.informative!.additionalData!.pausedAt)

          this.overrides.forEach(
            (override) =>
              (override.metadata!.informative!.additionalData!.resumeAt =
                this.override.metadata!.informative!.additionalData!.launchAt),
          )

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.launchOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }

    public async deleteRollout() {
      this.$confirm(`Delete this rollout?`, `This will permanently delete the rollout and it can not be recovered.`, {
        buttonTrueColor: 'error',
      }).then(async (confirmed) => {
        if (confirmed) {
          console.info('Deleting', this.overrides)

          await this.featuresStore.deleteOverrides(this.feature, this.overrides, this.references)

          this.$router.replace(
            `/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/advanced`,
          )
        }
      })
    }

    public async enableRollout() {
      const state = featureOverrideState(this.override).toLowerCase().slice(0, -1)

      this.$confirm(
        `Enable this rollout?`,
        `This will re-enable the rollout and ${state} feature for its current rollout step users.`,
        {
          buttonTrueColor: 'success',
        },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Enabling', this.overrides)

          const dateNow = this.$dayjs().toISOString()

          const overrides = this.overrides.concat(...this.references.map((r: any) => r.overrides))

          overrides.forEach((override) => (override.state = Override_State.ACTIVE))
          overrides.forEach((override) => delete override.metadata?.informative!.additionalData!.disabledAt)

          overrides.forEach((override) => (override.metadata!.informative!.additionalData!.resumeAt = dateNow))
          overrides.forEach((override) => (override.metadata!.informative!.additionalData!.enabledAt = dateNow))

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.launchOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }

    public async disableRollout() {
      this.$confirm(
        `Disable this rollout?`,
        `Disable the rollout and possibly the feature as well for the current rollout step users?`,
        {
          buttonTrueColor: 'error',
        },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Disabling rollout', this.override)

          const dateNow = this.$dayjs().toISOString()

          const overrides = this.overrides.concat(...this.references.map((r: any) => r.overrides))

          overrides.forEach((override) => (override.state = Override_State.DISABLED))
          overrides.forEach((override) => delete override.metadata!.informative!.additionalData!.enabledAt)
          overrides.forEach((override) => (override.metadata!.informative!.additionalData!.disabledAt = dateNow))

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.pauseOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }

    public async enableFeature() {
      this.$confirm(
        `Enable feature in ${this.rolloutView}?`,
        'This will re-enable the feature for the current rollout step users and unpause the rollout.',
        { buttonTrueColor: 'error' },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Enabling feature', this.override)

          delete this.override.metadata!.informative!.additionalData!.disabledAt

          this.override.metadata!.informative!.additionalData!.enabledAt = this.$dayjs().toISOString()

          if (this.override.parameters?.enabled?.oneOf?.$case === 'boolean') {
            this.override.parameters.enabled.oneOf.boolean = true
          }

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)
        }
      })
    }

    public async disableFeature() {
      this.$confirm(
        `Disable feature in ${this.rolloutView}?`,
        'This will disable the feature for the current rollout step users and pause the rollout.',
        { buttonTrueColor: 'error' },
      ).then(async (confirmed) => {
        if (confirmed) {
          console.info('Disabling feature', this.override)

          delete this.override.metadata!.informative!.additionalData!.enabledAt

          this.override.metadata!.informative!.additionalData!.disabledAt = this.$dayjs().toISOString()

          if (this.override.parameters?.enabled?.oneOf?.$case === 'boolean') {
            this.override.parameters.enabled.oneOf.boolean = false
          }

          await this.featuresStore.updateOverrides(this.feature, this.overrides, this.references)

          await this.featuresStore.pauseOverridesRollout(this.feature, this.overrides, this.references)
        }
      })
    }
  }

  export default toNative(StatusAlerts)
</script>
