<template>
  <v-alert
    :color="
      override
        ? overrideRolloutStatus(feature, 'disable') !== 'INACTIVE'
          ? 'error'
          : envFeatureStatus === 'DISABLED'
            ? 'error'
            : progressColors[envRolloutStatus].replace('grey', 'grey-darken-2')
        : 'grey-darken-2'
    "
    class="flex-shrink-1 flex-grow-0 my-8 py-4 px-6"
  >
    <div class="text-h5 font-weight-light">
      <template
        v-if="rolloutView.startsWith('advanced') && !override?.metadata?.informative?.additionalData?.createdAt"
      >
        Create the rollout first to be able to set launch time and launch it
      </template>
      <template v-else>
        <template v-if="overrideRolloutStatus(feature, 'disable') !== 'INACTIVE'">
          <template v-if="envRolloutStatus === 'DISABLED'">
            Both feature and the rollout is disabled for everyone
          </template>
          <template v-else-if="envRolloutStatus === 'ACTIVE'">
            Feature is disabled for everyone, but rollout is active
          </template>
          <template v-else-if="envRolloutStatus === 'PAUSED'">
            Feature is disabled for everyone and rollout is paused
          </template>
          <template v-else-if="envRolloutStatus === 'SCHEDULED'">
            Feature is disabled for everyone, but rollout is active
          </template>
          <template v-else-if="envRolloutStatus === 'FINALIZED'">
            Feature is disabled for everyone and rollout has finished
          </template>
          <template v-else>Feature is disabled for everyone and rollout is not active</template>
        </template>

        <template v-else-if="envRolloutStatus === 'DISABLED'">
          Rollout is disabled and has no effect for any user
        </template>

        <template v-else-if="envRolloutStatus === 'ACTIVE'">
          Feature is {{ envFeatureStatus.toLowerCase() }} for some users and rollout is active
        </template>
        <template v-else-if="envRolloutStatus === 'PAUSED'">
          Feature is {{ envFeatureStatus.toLowerCase() }} for some users and rollout is paused
        </template>

        <template v-else-if="envRolloutStatus === 'FINALIZED'">
          Feature is {{ envFeatureStatus.toLowerCase() }} for all users and rollout is finished
        </template>
        <template v-else-if="envRolloutStatus === 'SCHEDULED'">
          Rollout has been launched and currently scheduled to be started
        </template>
        <template v-else-if="rolloutView.startsWith('advanced')">
          This rollout has not been launched and has no effect for any users
        </template>
        <template v-else>Feature is not yet enabled since rollout is not yet launched</template>

        {{ rolloutView.startsWith('advanced') ? '' : 'for ' + envRolloutTarget }}
      </template>
    </div>

    <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
      <template v-if="isFeatureAdmin">
        <template v-if="isProjectEditor(feature)">
          Your feature admin and project rights allow you to create, modify and control all the rollouts
        </template>
        <template v-else-if="userEmail === feature?.metadata?.informative?.additionalData?.createdBy">
          You created this feature flag so you are allowed to create, modify and control all the rollouts
        </template>
        <template v-else>
          You can only view the feature rollouts since you do not have the editing rights to the project
        </template>
      </template>
      <template v-else>
        You can only view the feature rollouts since you do not have the Waltari feature admin rights
      </template>
    </div>

    <template #append>
      <div v-if="!override" class="text-button text-black">LOADING</div>

      <div v-else-if="overrideRolloutStatus(feature, 'disable') !== 'INACTIVE'" class="text-button text-red">
        DISABLED
      </div>

      <div v-else class="text-button" :class="'text-' + statusColors[envRolloutStatus]">{{ envRolloutStatus }}</div>
    </template>
  </v-alert>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { progressColors, statusColors } from '#views/features/constants'

  import { currentEnvRolloutTarget, featureOverrideState, overrideRolloutStatus } from '#views/features/utilities'
  import { isProjectEditor } from '#views/projects/utilities'

  import { AppStore, ProjectsStore } from '#stores'

  import { Feature, Override, RolloutTarget } from '#types'

  @Component({})
  class StatusHeader extends Vue {
    @Prop() public feature!: Feature
    @Prop() public override!: Override

    @Prop() public rolloutView!: RolloutTarget

    public readonly statusColors = statusColors
    public readonly progressColors = progressColors

    public readonly isProjectEditor = isProjectEditor
    public readonly featureOverrideState = featureOverrideState
    public readonly overrideRolloutStatus = overrideRolloutStatus

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()

    public get userEmail() {
      return this.appStore.email
    }

    public get featureProject() {
      return this.projectsStore.projects.find((p) => p.id === this.feature?.metadata?.informative?.labels?.project)
    }

    public get isFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin
    }

    public get envFeatureStatus() {
      return this.rolloutView.startsWith('advanced')
        ? featureOverrideState(this.override)
        : featureOverrideState(this.feature, currentEnvRolloutTarget(this.feature, this.rolloutView))
    }

    public get envRolloutStatus() {
      return this.rolloutView.startsWith('advanced')
        ? overrideRolloutStatus(this.override)
        : overrideRolloutStatus(this.feature, currentEnvRolloutTarget(this.feature, this.rolloutView))
    }

    public get envRolloutTarget() {
      const target = currentEnvRolloutTarget(this.feature, this.rolloutView)

      switch (target) {
        case 'sandbox':
          return 'sandbox apps'

        case 'staging':
          return 'staging app'

        case 'release':
          return 'release apps'

        case 'ouranians':
          return 'ouranians only'

        case 'experimental':
          return 'experimental app'

        default:
          return this.rolloutView
      }
    }
  }

  export default toNative(StatusHeader)
</script>
