<template>
  <v-tabs v-model="experimentGroup" show-arrows class="mb-6" style="max-width: 393px">
    <v-tab v-for="(override, i) in overrides" :key="i">
      {{ override.metadata!.informative!.displayName || 'Group' }}
    </v-tab>

    <v-btn
      v-if="experimentType === 'experiment-mv-testing'"
      small
      color="primary"
      class="ml-7 mr-n1 my-2"
      style="min-width: 28px; max-width: 28px"
      :disabled="!!overrides.some((o) => o.metadata?.informative?.additionalData?.launchAt)"
      @click="createExperimentGroup()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-tabs>

  <div v-for="(override, i) in overrides" :key="i">
    <template v-if="experimentGroup == i">
      <v-row>
        <v-col>
          <v-text-field
            v-model="override.metadata!.informative!.displayName"
            class="mr-n4"
            label="Group name"
            density="compact"
          />
        </v-col>

        <v-col class="d-flex flex-row align-center">
          <v-text-field
            v-if="
              override.criteria?.oneOf?.$case === 'and' &&
              override.criteria.oneOf.and.expressions[0] &&
              override.criteria.oneOf.and.expressions[0].oneOf?.$case === 'predicate' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf?.$case === 'user' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf?.$case === 'range' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf.range.lowerBound
            "
            v-model="
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf.range.lowerBound.integerPart
            "
            type="number"
            label="Lower %"
            density="compact"
            validate-on="lazy blur"
            :rules="[(v: string) => verifyPercentile('lowerBound', override, v)]"
          />
          <div class="mt-n3 py-4 px-2">-</div>
          <v-text-field
            v-if="
              override.criteria?.oneOf?.$case === 'and' &&
              override.criteria.oneOf.and.expressions[0] &&
              override.criteria.oneOf.and.expressions[0].oneOf?.$case === 'predicate' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf?.$case === 'user' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf?.$case === 'range' &&
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf.range.upperBound
            "
            v-model="
              override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf.range.upperBound.integerPart
            "
            type="number"
            label="Upper %"
            density="compact"
            validate-on="lazy blur"
            :rules="[(v: string) => verifyPercentile('upperBound', override, v)]"
          />
        </v-col>

        <v-col v-if="overrides.length > 3" style="max-width: 24px; padding: 0" class="mx-2 pt-8">
          <v-tooltip top>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="mdi-delete"
                class="mt-n5 ml-n4"
                :disabled="!!override.metadata?.informative?.additionalData!.launchAt"
                @click="deleteExperimentGroup(i)"
              />
            </template>
            <span style="white-space: nowrap">Delete experiment group</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { createOverride, updateOverridesFromTemplate } from '#views/features/utilities'

  import { ReleasesStore } from '#stores'

  import { Feature, Override, Reference } from '#types'

  import { nanoId } from '#utilities'

  @Component({})
  class RolloutGroups extends Vue {
    @Prop() public active!: number

    @Prop() public feature!: Feature
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    private latestVersions: any = { ios: {}, android: {} }

    protected readonly releasesStore = new ReleasesStore()

    public get experimentType() {
      return this.overrides.length === 2 ? 'experiment-ab-testing' : 'experiment-mv-testing'
    }

    public get experimentGroup() {
      return this.active
    }

    public set experimentGroup(index: number) {
      this.$router.replace(this.$route.path.replace(/\/\d+$/, `/${index}`))
    }

    public verifyPercentile(bound: string, override: Override, value: string) {
      const expression: any =
        override.criteria?.oneOf?.$case === 'and' &&
        override.criteria.oneOf.and.expressions[0] &&
        override.criteria.oneOf.and.expressions[0].oneOf?.$case === 'predicate' &&
        override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf?.$case === 'user' &&
        override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf?.$case === 'range' &&
        override.criteria.oneOf.and.expressions[0].oneOf.predicate.oneOf.user.oneOf.range

      expression[bound].integerPart = parseInt(value)

      if (bound === 'lowerBound') {
        expression.upperBound.integerPart = Math.min(
          100,
          Math.max(expression.lowerBound.integerPart + 1, expression.upperBound.integerPart),
        )
      } else {
        expression.lowerBound.integerPart = Math.max(
          0,
          Math.min(expression.upperBound.integerPart - 1, expression.lowerBound.integerPart),
        )
      }

      return true
    }

    public async mounted() {
      this.latestVersions.ios = await this.releasesStore.fetchLatestVersion('ios')
      this.latestVersions.android = await this.releasesStore.fetchLatestVersion('android')
    }

    public createExperimentGroup() {
      this.overrides.push({
        ...createOverride(this.feature, this.overrides[this.active].metadata?.uid?.slice(0, -6) + nanoId()),
      })

      // Data between overrides will be synced only on save

      this.references.forEach((reference) => {
        reference.overrides.push(createOverride(reference.feature))
      })

      updateOverridesFromTemplate(this.overrides, this.experimentType, this.latestVersions)

      this.$router.replace(this.$route.path.replace(/\/\d+$/, `/${this.overrides.length - 1}`))
    }

    public deleteExperimentGroup(config: number) {
      this.$router.replace(this.$route.path.replace(/\/\d+$/, `/${Math.max(0, config - 1)}`))

      setTimeout(() => {
        this.overrides.splice(config, 1)

        for (const reference of this.references) {
          reference.overrides.splice(config, 1)
        }

        updateOverridesFromTemplate(this.overrides, this.experimentType, this.latestVersions)
      }, 0)
    }
  }

  export default toNative(RolloutGroups)
</script>

<style lang="scss" scoped>
  :deep(.v-tabs) {
    .v-slide-group__prev,
    .v-slide-group__next {
      margin: 0 -8px;
    }

    .v-slide-group--is-overflowing {
      max-width: 395px;
      min-width: 395px;
      margin: 0 -30px;

      .v-btn {
        margin-right: 12px !important;
      }
    }
  }

  :deep(.uppercased) {
    input {
      text-transform: uppercase;
    }
  }
</style>
