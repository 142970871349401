<template>
  <div>
    <v-row no-gutters align="center">
      <v-col class="mr-4" cols="2">
        <v-select
          hide-details
          density="compact"
          label="Text style"
          :items="textStyleItems"
          :model-value="textStyle()"
          :disabled="data.plain_text"
          @update:model-value="setTextStyle($event)"
        />
      </v-col>

      <v-divider vertical class="mt-3 mx-2" style="max-height: 30px" />

      <v-btn
        icon="mdi-format-bold"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      />
      <v-btn
        icon="mdi-format-italic"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      />

      <v-btn
        icon="mdi-format-underline"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
      />

      <v-btn
        icon="mdi-format-strikethrough"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
      />

      <v-divider vertical class="mt-3 mx-2" style="max-height: 30px" />

      <v-btn
        icon="mdi-format-list-bulleted"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('bullet-list') }"
        @click="editor.chain().focus().toggleBulletList().run()"
      />

      <v-btn
        icon="mdi-format-list-numbered"
        :disabled="data.plain_text"
        :class="{ 'button-active': editor && editor.isActive('ordered-list') }"
        @click="editor.chain().focus().toggleOrderedList().run()"
      />

      <v-divider vertical class="mt-3 mx-2" style="max-height: 30px" />

      <v-menu
        offset-y
        left
        nudge-top="-8"
        nudge-right="8"
        min-width="300"
        max-width="300"
        max-height="500"
        :disabled="inTemplates"
      >
        <template #activator="{ props }">
          <div class="d-flex flex-row" v-bind="props">
            <v-btn
              icon="mdi-view-grid-plus-outline"
              variant="plain"
              :class="{ 'button-active': !!data.contents.templateId }"
              :disabled="!templates.length"
            />

            <v-icon color="grey" class="ml-n3 mt-2">mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-subheader>Use variant="text" from template</v-list-subheader>

          <template v-for="template in templates">
            <v-list-item
              v-for="component in template.facets.view.components.filter((c: any) => c.type === 'rich_text')"
              :key="template.id + '-' + component.id"
              two-line
              :title="template.name"
              @click="useTemplate(template, component)"
            >
              <v-list-item-subtitle>
                <div style="max-width: 280px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{ component.contents.text.replace(/<[^>]*>/g, '') }}
                </div>
              </v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-spacer />

      <v-switch v-model="data.plain_text" label="Handle as plain text" />
    </v-row>
  </div>

  <Translatable :data="data.contents" class="textarea">
    <v-textarea v-if="data.plain_text" v-model="data.contents.text" rows="3" />

    <editor-content v-else class="mb-4" :editor="editor" spellcheck="true" lang="en" />
  </Translatable>

  <v-divider v-if="data.placeholders.length" class="my-4" />

  <div v-for="placeholder in data.placeholders" :key="placeholder.offset" class="mb-4">
    <span class="text-body-1">Placeholder #{{ placeholder.index }}</span>
    <Statement v-model="placeholder.statement" type="string" required label="Statement" />
  </div>

  <div
    v-if="data.contents.templateId && data.contents.translationId.includes('template-component')"
    class="caption orange--text"
  >
    Note: If you edit this text it will only change for this component. To change the text for all components using this
    text please edit the text in the
    <router-link :to="'/templates/' + data.contents.templateId">
      <a link class="text-primary">template</a>
    </router-link>
    .
  </div>
</template>

<script lang="ts">
  import { EditorContent } from '@tiptap/vue-3'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { blockNodes, getNewEditor } from '#views/messages/components/richTextEditorHelper'

  import { AppStore, TemplatesStore } from '#stores'

  import { UIComponentRichText, UIComponentRichTextFormatPlaceholderMatch } from '#types'

  @Component({
    components: {
      EditorContent,
    },
    inheritAttrs: false,
  })
  class RichText extends Vue {
    @Prop() public id!: string
    @Prop() public data!: UIComponentRichText

    public editor: any = null

    protected AppStore = new AppStore()
    protected templatesStore = new TemplatesStore()

    public get templates() {
      return this.templatesStore.components.filter((t) =>
        t.facets?.view?.components.find((c: any) => c.type === 'rich_text'),
      )
    }

    public get inTemplates() {
      return this.AppStore.inTemplates
    }

    public get textStyleItems() {
      return blockNodes
    }

    @Watch('data.plain_text')
    protected onPlainTextChanged() {
      if (!this.data.plain_text) {
        this.data.contents.text = `<node-paragraph-m><p>${this.data.contents.text}</p></node-paragraph-m>`

        this.editor = getNewEditor(this.data, true, this.updateData)
      } else {
        this.data.contents.text = this.editor.getText()

        if (this.editor) {
          this.editor.destroy()
        }
      }
    }

    public created() {
      if (!this.data.plain_text) {
        this.editor = getNewEditor(this.data, true, this.updateData)
      }
    }

    public beforeUnmount() {
      if (this.editor) {
        this.editor.destroy()
      }
    }

    public textStyle() {
      for (const node of blockNodes) {
        if (this.editor?.isActive('root', { style: node.value })) {
          return node.value
        }
      }

      return null
    }

    public setTextStyle(value: string) {
      this.editor.chain().focus().setStyle(value).run()
    }

    public updateData(text: string, placeholders: UIComponentRichTextFormatPlaceholderMatch[]) {
      if (this.data.contents.translationId.startsWith('template-component') && text !== this.data.contents.text) {
        delete this.data.contents.templateId

        this.data.contents.translationId = createDefaultTranslatable(
          `${this.id}:view`,
          this.data.contents.comment,
        ).translationId
      }

      this.data.contents.text = text
      this.data.placeholders = placeholders
    }

    public useTemplate(template: any, component: any) {
      this.data.contents = { ...component.contents, templateId: template.id }

      if (this.editor) {
        this.editor.destroy()
      }

      this.editor = getNewEditor(this.data, true, this.updateData)
    }
  }

  export default toNative(RichText)
</script>

<style lang="scss" scoped>
  .button-active {
    border: 1px solid gray;
  }
</style>

<style lang="scss">
  // Scoped styles don't work with the editor classes.

  ul > li > p,
  ol > li > p {
    margin-bottom: 4px !important;
  }

  node-heading,
  node-title-m {
    color: var(--v-theme-foregound);
    font-size: 22px;
    font-weight: bold;
  }
  node-heading-secondary,
  node-title-s {
    color: var(--v-theme-foregound);
    font-size: 20px;
  }
  node-body,
  node-paragraph-m {
    color: var(--v-theme-foregound);
    font-size: 16px;
    line-height: 24px;
  }
  node-body-small,
  node-paragraph-s {
    color: var(--v-theme-foregound);
    font-size: 15px;
    line-height: 21px;
  }
  node-subtitle,
  node-label-xs {
    color: var(--v-theme-foregound);
    font-size: 15px;
    opacity: 0.6;
  }
  node-label-m {
    color: var(--v-theme-foregound);
    font-size: 16px;
    opacity: 0.6;
  }
  node-label-l {
    color: var(--v-theme-foregound);
    font-size: 18px;
    opacity: 0.6;
  }

  .reversed {
    .v-input__slot {
      flex-direction: row-reverse;
    }
    .v-input--selection-controls__input {
      margin-left: 20px;
      margin-right: 6px;
    }
  }

  .textarea {
    .v-text-field__slot textarea {
      padding: 4px 8px;
      border: 1px dotted rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.01);

      &:focus {
        outline: 2px solid rgb(var(--v-theme-primary)) !important;
      }
    }
  }

  .ProseMirror {
    border: 1px dotted rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.01);

    &.ProseMirror-focused {
      border: 1px dotted transparent;
      outline: 2px solid rgb(var(--v-theme-primary)) !important;
    }
  }
</style>
