<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <template
    v-if="
      criteria?.expression?.oneOf?.$case === 'predicate' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
    "
  >
    <v-combobox
      label="Label id to match"
      placeholder="Type a new label id or select an existing one"
      :loading="isLoading"
      :items="existingLabels.map((l) => l.name)"
      :error-messages="
        !!createLabel && validateLabelName(createLabel) !== true
          ? ['Needs to be longer than 10 characters and use only small letters, numbers or :_- characters']
          : []
      "
      :disabled="!!criteria.metadata?.changeRecord?.createdAt"
      :model-value="criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals || null"
      @update:search-input="createLabel = $event"
      @update:model-value="formatLabelName($event)"
    />

    <v-alert
      v-if="
        !isLoading &&
        !existingLabels.find(
          (l) =>
            l.name ===
            (criteria?.expression?.oneOf?.$case === 'predicate' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals' &&
              criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals),
        ) &&
        validateLabelName(
          (criteria?.expression?.oneOf?.$case === 'predicate' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals' &&
            criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) ||
            '',
        ) === true
      "
      text="Given label does not exist and it will be created when you save this criteria"
      color="info"
      class="mt-n2 mb-4"
    />

    <div
      v-if="
        !isLoading &&
        !!createLabel &&
        validateLabelName(createLabel) === true &&
        !existingLabels.find((l) => l.name === createLabel)
      "
      class="d-flex flex-row"
    >
      <v-spacer />

      <v-btn text="Add users" size="small" color="primary" @click="setLabelName()" />
    </div>

    <template
      v-else-if="
        criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals &&
        (!createLabel ||
          createLabel === criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) &&
        validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) === true
      "
    >
      <div class="text-button my-2">Users ({{ labelUsers.length }})</div>

      <v-select
        label="Users environment"
        :items="cloudEnvs"
        :loading="isLoading"
        :model-value="criteria.metadata!.informative!.labels.labelsEnv"
        @update:model-value="
          (criteria.metadata!.informative!.labels.labelsEnv = $event),
            updateLabelUsers(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals)
        "
      />

      <template v-if="!isLoading">
        <CsvImportDialog :json-schema="labelUsersSchema" @import="importLabelUsers($event)">
          <template #default>
            <v-btn block variant="outlined" color="primary" text="Import users from CSV file" />
          </template>
        </CsvImportDialog>

        <p class="text-center pa-2">OR</p>

        <v-text-field
          v-model.trim="userText"
          v-lowercase
          single-line
          label="Type uuid / email to add or remove"
          prepend-inner-icon="mdi-account"
          :loading="isLoading || isWaiting"
          :hint="userUid.includes('@') ? 'Press enter to search with the email' : ''"
          :rules="[
            (text: string) =>
              text && !text.includes('@')
                ? validateUserUid(text)
                : !text || isLoading || userUid
                  ? true
                  : 'No account found with given email',
          ]"
          :disabled="
            validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) !== true
          "
          @update:model-value="userUid = userText"
          @keyup.enter="convertEmailToUid()"
        />

        <div class="d-flex flex-row">
          <v-spacer />

          <v-btn
            size="small"
            class="mr-2"
            color="error"
            text="Remove"
            :disabled="
              !userUid ||
              validateUserUid(userUid) !== true ||
              labelUsers.find((u) => u.userUid === userUid) === undefined ||
              !validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals)
            "
            @click="removeLabelUsers()"
          />

          <v-btn
            size="small"
            color="primary"
            text="Add"
            :disabled="
              !userUid ||
              validateUserUid(userUid) !== true ||
              labelUsers.find((u) => u.userUid === userUid) !== undefined ||
              !validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals)
            "
            @click="appendLabelUsers(userUid)"
          />
        </div>

        <template v-if="!!labelUsers.length">
          <div class="text-button mt-2">Last added</div>

          <div class="mt-4 mb-2 mx-n3">
            <v-data-table
              dense
              hide-default-header
              hide-default-footer
              :headers="userHeaders"
              :items-per-page="usersPerPage"
              :items="labelUsers.filter((u) => u.userUid.includes(userUid))"
              :no-data-text="userUid ? 'No users matching uuid' : 'No users saved for the label'"
              @click:row="(_event: any, row: any) => (userUid = userText = row.item.userUid)"
            >
              <template #item.createdAt="{ item }">
                {{ item.createdAt ? $dayjs(item.createdAt).format('DD MMM YYYY') : 'Just now' }}
              </template>
            </v-data-table>
          </div>

          <div v-if="usersPerPage < labelUsers.length" class="text-right">
            <a style="font-size: 12px" @click="usersPerPage = usersPerPage + 100">Show more</a>
          </div>
        </template>
      </template>
    </template>
  </template>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { CsvImportDialog } from '@jouzen/outo-apps-toolkit'

  import { cloudEnvs, labelUsersSchema, userHeaders } from '#views/segments/constants'

  import { validateUserUid } from '#views/segments/utilities'

  import { LabelsStore } from '#stores'

  import { Criteria } from '#types'

  @Component({
    components: {
      CsvImportDialog,
    },
  })
  class CriteriaLabel extends Vue {
    @Prop() public criteria!: Criteria

    @Emit('validate')
    public emitValidate() {
      return this.labelValid
    }

    public labelValid = true

    public userUid = ''
    public userText = ''
    public createLabel = ''

    public usersPerPage = 10

    public readonly cloudEnvs = cloudEnvs
    public readonly userHeaders = userHeaders

    public readonly validateUserUid = validateUserUid

    private readonly labelsStore = new LabelsStore()

    private readonly cloudEnv = import.meta.env.VITE_CLOUD_ENV.replace('dev', 'test')

    public get isLoading() {
      return this.labelsStore.loading
    }

    public get isWaiting() {
      return this.labelsStore.waiting
    }

    public get labelUsers() {
      return this.labelsStore.users
    }

    public get selectedLabel() {
      return this.labelsStore.label
    }

    public get existingLabels() {
      return this.labelsStore.labels.sort((a: any, b: any) => a.name.localeCompare(b.name))
    }

    public get labelUsersSchema() {
      const schema = labelUsersSchema

      const labelUserMap = new Map(this.labelUsers.map((u) => [u.userUid, true]))

      schema.userUid['filters'] = [
        {
          name: 'Filter not found uuids',
          filter: (userUid, _index, _rows) => userUid !== 'NOT FOUND',
        },
        {
          name: 'Filter not valid uuids',
          filter: (userUid, _index, _rows) => validateUserUid(userUid as string) === true,
        },
        {
          name: 'Filter existing uuids',
          filter: (userUid, _index, _rows) => !labelUserMap.get(userUid),
        },

        {
          name: 'Filter duplicate uuids',
          filter: (userUid, index, rows) => !(rows.get(userUid) !== index),
        },
      ]

      schema.userUid['converters'] = [
        {
          name: 'Emails to uuids',
          convert: async (rows) => {
            const values = rows as string[]

            const emails = values.filter((v) => v.includes('@'))

            if (!emails.length) {
              return rows
            } else {
              const uuids = await this.labelsStore.convertEmailsToUids(
                this.criteria.metadata?.informative?.labels.labelsEnv || this.cloudEnv,
                emails,
              )

              return values.map((v) => (v.includes('@') ? uuids.shift() || 'NOT FOUND' : v))
            }
          },
        },
      ]

      return schema
    }

    @Watch('criteria', { immediate: true })
    protected contentChanged() {
      if (
        this.criteria?.expression?.oneOf?.$case === 'predicate' &&
        this.criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
        this.criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
        this.criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
      ) {
        if (this.criteria && !this.criteria.metadata?.informative?.labels.labelsEnv) {
          this.criteria.metadata!.informative!.labels.labelsEnv = this.cloudEnv
        }

        const label = this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals

        if (!label || this.selectedLabel !== label) {
          this.setLabelName()

          this.updateLabelUsers(label)
        }
      }
    }

    public setLabelName() {
      this.labelsStore.loadLabels()

      this.createLabel = ''
    }

    public formatLabelName(label: string) {
      if (label !== null) {
        label = label || this.createLabel || ''

        if (
          label &&
          !label.startsWith('criteria:') &&
          this.validateLabelName(label) === true &&
          !this.existingLabels.find((l: any) => l.name === label)
        ) {
          label = 'criteria:' + label
        }

        if (
          this.criteria.expression?.oneOf?.$case === 'predicate' &&
          this.criteria.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
          this.criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
          this.criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
        ) {
          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals = this.createLabel = label
        }
      }
    }

    public validateLabelName(label?: string) {
      label = (label || '').replace('criteria:', '')

      const regex = new RegExp('^[a-z0-9][a-z0-9:_-]{3,254}')

      const result =
        (label.length > 10 && regex.test(label)) || !!this.existingLabels.find((l: any) => l.name === label)

      this.labelValid = result === true

      this.emitValidate()

      return result
    }

    public appendLabelUsers(labelUserUid: string) {
      this.labelUsers.unshift({ userUid: labelUserUid })

      this.contentUpdatedAt()

      this.userUid = ''
      this.userText = ''
    }

    public importLabelUsers(users: { userUid: string }[]) {
      this.labelUsers.unshift(...users)

      this.contentUpdatedAt()
    }

    public removeLabelUsers() {
      this.labelUsers.splice(
        this.labelUsers.findIndex((u: any) => u.userUid === this.userUid),
        1,
      )

      this.contentUpdatedAt()

      this.userUid = ''
      this.userText = ''
    }

    public async convertEmailToUid() {
      const cloudEnv = this.criteria.metadata?.informative?.labels?.labelsEnv || this.cloudEnv

      const uuids = await this.labelsStore.convertEmailsToUids(cloudEnv, [this.userText])

      this.userUid = uuids[0] || ''

      return uuids
    }

    public async updateLabelUsers(label: string) {
      const cloudEnv = this.criteria.metadata?.informative!.labels.labelsEnv || this.cloudEnv

      await this.labelsStore.loadLabelUsers(cloudEnv, label)
    }

    private contentUpdatedAt() {
      this.criteria.metadata!.changeRecord!.lastModifiedAt = this.$dayjs().toISOString()

      this.emitValidate()
    }
  }

  export default toNative(CriteriaLabel)
</script>
