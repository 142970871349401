<template>
  <v-app-bar elevation="3">
    <v-app-bar-title class="ml-2">
      <v-btn icon="mdi-arrow-left" @click="$router.push('/features')" />

      <v-hover #default="{ props, isHovering }">
        <div v-bind="props" class="d-flex flex-row align-center justify-center">
          <span>{{ projectId.toUpperCase() }}/{{ featureId.toUpperCase() }}</span>

          <v-btn
            class="ml-2"
            icon="mdi-content-copy"
            :style="{ opacity: isHovering ? '1' : '0' }"
            @click="clipboard.copy(projectId + '/' + featureId)"
          />
        </div>
      </v-hover>
    </v-app-bar-title>

    <v-spacer />

    <template v-if="selectedFeature">
      <StatisticsChip
        textonly
        stat="count"
        unit="users"
        :loading="isLoading"
        :stats-data="featureStats"
        @open="statisticsChartRef.open()"
      />

      <v-tooltip
        location="bottom end"
        :text="
          'Last edited by ' +
          (selectedFeature.metadata?.informative?.additionalData?.updatedBy ||
            selectedFeature.metadata?.informative?.additionalData?.createdBy ||
            'system / unknown user') +
          ' - ' +
          $dayjs(
            selectedFeature.metadata?.changeRecord?.lastModifiedAt || selectedFeature.metadata?.changeRecord?.createdAt,
          ).format('HH:mm on DD MMM YYYY')
        "
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="mdi-history" />
        </template>
      </v-tooltip>

      <v-tooltip
        location="bottom end"
        :text="selectedFeature.metadata?.informative!.description + ' - Click for more info.'"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-information-outline"
            @click="openInfoUrl(selectedFeature.metadata!.informative!.referenceUrls[0])"
          />
        </template>
      </v-tooltip>

      <v-tooltip text="Notes / comments" location="bottom">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon :active="navDrawer === 'notes'" @click="togglePanel('notes')">
            <v-badge overlap color="primary" :content="commentsCount" :model-value="!!commentsCount">
              <v-icon>mdi-note-text-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
      </v-tooltip>

      <v-tooltip text="Edit feature flag" location="bottom end">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-square-edit-outline"
            :disabled="!isProjectEditor(selectedFeature)"
            :active="navDrawer === 'feature'"
            @click="togglePanel('feature')"
          />
        </template>
      </v-tooltip>
    </template>
  </v-app-bar>

  <v-container class="d-flex flex-column align-stretch justify-start flex-grow-1 flex-shrink-1 flex-nowrap fill-height">
    <template v-if="!selectedFeature">
      <div class="text-center" style="margin-top: calc(50vh - 196px)">
        <v-progress-circular size="96" color="primary" indeterminate />
      </div>
    </template>
    <template v-else>
      <div class="flex-shrink-1 bg-background mt-n8 pt-8" style="position: sticky; top: 124px; z-index: 10">
        <RolloutsBar :feature="selectedFeature" :rollout-view="rolloutView || 'advanced-listing'" />
      </div>

      <div class="flex-shrink-1">
        <RolloutsInfo
          v-if="
            (!!rolloutIdx || !!rolloutView) &&
            ((rolloutView && !rolloutView.startsWith('advanced')) || !!activeOverrides.length)
          "
          :active="activeIdx"
          :feature="selectedFeature"
          :overrides="activeOverrides"
          :references="linkedOverrides"
          :preview-only="navDrawer === 'rollout' || !isProjectEditor(selectedFeature)"
          :rollout-view="rolloutView || 'advanced-' + (rolloutIdx || 'listing')"
        />

        <RolloutsList v-else :feature="selectedFeature" :rollout-id="rolloutId" @create="createRollout()" />
      </div>
    </template>
  </v-container>

  <NotesPanel v-if="!!selectedFeature" title="Feature" :path="featurePath" />

  <FeaturePanel :feature="selectedFeature" :features="allFeatures" :criterias="allCriterias" />

  <RolloutPanel
    :active="activeIdx"
    :feature="selectedFeature"
    :features="allFeatures"
    :criterias="allCriterias"
    :overrides="activeOverrides"
    :references="linkedOverrides"
    @close="editedOverrides = []"
    @change="editedOverrides = $event"
  />

  <StatisticsChart
    v-if="selectedFeature"
    ref="statisticsChartRef"
    unit="day"
    title="Active users with feature flag enabled"
    :loading="isLoading"
    :guides="featureEvents"
    :header="selectedFeature.metadata?.name?.toUpperCase()"
    :labels="['Users with flag enabled', 'Users with flag enabled current day']"
    :stats-data="featureStats"
  />

  <NotesDialog ref="notesDialog" />
</template>

<script lang="ts">
  import { UseClipboardReturn, useClipboard } from '@vueuse/core'

  import { Component, Prop, Ref, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { Context } from '@jouzen/control-api/metadata'

  import {
    createOverride,
    findFeatureOverride,
    forEachFeatureOverride,
    overrideRolloutStatus,
    updateOverridesFromTemplate,
  } from '#views/features/utilities'
  import { getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ReleasesStore, SegmentsStore } from '#stores'

  import { Dialog, Feature, Override, Reference, RolloutTarget } from '#types'

  import { nanoId } from '#utilities'

  @Component({})
  class FeatureView extends Vue {
    @Prop() public featureId!: string
    @Prop() public projectId!: string

    @Prop() public rolloutId!: string
    @Prop() public rolloutIdx!: string

    @Prop() public featurePath!: string

    @Prop() public rolloutView!: RolloutTarget

    public commentsCount = 0

    public groupOverrides: Override[] = []

    public activeOverrides: Override[] = []
    public createOverrides: Override[] = []
    public editedOverrides: Override[] = []

    public linkedOverrides: Reference[] = []

    public selectedFeature: Feature | null = null

    public readonly getProjectKey = getProjectKey
    public readonly isProjectEditor = isProjectEditor

    @Ref() public readonly statisticsChartRef!: Dialog

    @Setup(() => useClipboard())
    public readonly clipboard!: UseClipboardReturn<false>

    private readonly appStore = new AppStore()
    private readonly featuresStore = new FeaturesStore()
    private readonly segmentsStore = new SegmentsStore()
    private readonly releasesStore = new ReleasesStore()

    private latestVersions: any = { ios: {}, android: {} }

    private featureNotesUnsubscribe: Unsubscribe | null = null

    public get activeIdx() {
      return +(this.rolloutIdx || 0)
    }

    public get navDrawer() {
      return this.appStore.navDrawer
    }

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get allFeatures() {
      return this.featuresStore.features
    }

    public get allCriterias() {
      return this.segmentsStore.criterias
    }

    public get featureStats() {
      if (this.selectedFeature && this.featuresStore.stats?.seriesLabels) {
        const name = `${getProjectKey(this.selectedFeature).toLowerCase()}/${this.selectedFeature.metadata?.name}`

        const index = this.featuresStore.stats?.seriesLabels.findIndex((item: any) => item[1] === name)

        if (index > -1) {
          const seriesForId = this.featuresStore.stats?.series[index]

          return { xValues: this.featuresStore.stats?.xValues, series: seriesForId }
        }
      }

      return null
    }

    public get featureEvents() {
      const events: any[] = []

      if (this.featureStats) {
        forEachFeatureOverride(this.selectedFeature!, (override) => {
          if (override.rolloutOneOf?.$case === 'rollout') {
            override.rolloutOneOf?.rollout?.stages.forEach((s: any, i: number) => {
              if (
                s.scheduledAt &&
                this.$dayjs(s.scheduledAt) > this.$dayjs(this.featureStats!.xValues[0]).add(1, 'day')
              ) {
                events.push({
                  label: override.metadata?.uid?.split('_').slice(0, -1).join('_').toUpperCase() + ' - Step ' + (i + 1),
                  value: this.$dayjs(s.scheduledAt).valueOf(),
                })
              }
            })
          }
        })
      }

      return events
    }

    @Watch('isLoading', { immediate: true })
    protected isLoadingChanged() {
      if (!this.isLoading && this.featureId && this.projectId) {
        this.featureIdChanged()

        this.selectedFeatureChanged()
      }
    }

    @Watch('featureId')
    protected featureIdChanged() {
      if (!this.isLoading) {
        if (this.featureNotesUnsubscribe) {
          this.featureNotesUnsubscribe()
        }

        this.featureNotesUnsubscribe = onSnapshot(
          query(collection(getFirestore(), `${this.featurePath}/notes`)),
          (snap) => {
            this.commentsCount = snap.docs.length
          },
        )

        this.selectedFeature =
          this.allFeatures.find((f) => f.metadata?.name === this.featureId && getProjectKey(f) === this.projectId) ||
          null

        this.rolloutViewChanged()
      }
    }

    @Watch('rolloutId')
    protected rolloutIdChanged() {
      if (!this.isLoading && !this.rolloutId) {
        this.appStore.closeNavDrawer()
      }
    }

    @Watch('rolloutIdx')
    protected rolloutIdxChanged() {
      if (!this.isLoading && this.selectedFeature) {
        this.updateActiveOverrides()
      }
    }

    @Watch('rolloutView')
    protected rolloutViewChanged() {
      if (!this.isLoading && this.selectedFeature) {
        this.createOverrides = []
        this.editedOverrides = []

        this.appStore.closeNavDrawer()

        this.checkForExistingOverride()
      }
    }

    @Watch('selectedFeature')
    protected selectedFeatureChanged() {
      if (!this.isLoading && this.selectedFeature) {
        console.info('Feature', this.selectedFeature)
      }
    }

    @Watch('createOverrides')
    protected createOverridesChanged() {
      if (!this.isLoading && this.selectedFeature) {
        this.updateActiveOverrides()
      }
    }

    @Watch('editedOverrides')
    protected editedOverridesChanged() {
      if (!this.isLoading && this.selectedFeature) {
        // When editing is cancelled for new rollout then go to list view

        if (this.createOverrides.length && !this.editedOverrides.length) {
          if (!this.createOverrides.every((o) => o.metadata?.informative?.labels.rollout)) {
            this.$router.push(`/features/${this.projectId}/${this.featureId}/rollouts/advanced`)

            this.createOverrides = []
          }
        }

        this.updateActiveOverrides()
      }
    }

    @Watch('activeOverrides')
    protected activeOverridesChanged() {
      if (!this.isLoading && this.selectedFeature) {
        if (this.activeOverrides.length) {
          console.info('Overrides', this.activeOverrides)

          if (
            this.selectedFeature &&
            (this.rolloutView === 'sandbox' || this.rolloutView === 'staging' || this.rolloutView === 'experimental')
          ) {
            if (
              overrideRolloutStatus(this.activeOverrides[0]) === 'INACTIVE' &&
              !this.activeOverrides[0].metadata?.informative?.additionalData?.launchAt
            ) {
              this.activeOverrides[0].metadata!.informative!.additionalData!.launchAt = this.$dayjs().toISOString()
            }
          }

          this.updateLinkedOverrides()
        }

        if (this.rolloutView && !this.activeOverrides[this.activeIdx]) {
          this.$router.push(`/features/${this.projectId}/${this.featureId}/rollouts/${this.rolloutView}`)
        }
      }
    }

    @Watch('linkedOverrides')
    protected linkedOverridesChanged() {
      if (!this.isLoading && this.selectedFeature) {
        console.info('References', this.linkedOverrides)
      }
    }

    public async mounted() {
      this.latestVersions.ios = await this.releasesStore.fetchLatestVersion('ios')
      this.latestVersions.android = await this.releasesStore.fetchLatestVersion('android')
    }

    public beforeUnmount() {
      if (this.featureNotesUnsubscribe) {
        this.featureNotesUnsubscribe()

        this.featureNotesUnsubscribe = null
      }
    }

    public openInfoUrl(url: string) {
      window.open(url, '_blank')
    }

    public togglePanel(panel: string) {
      this.appStore.toggleNavDrawer(panel)
    }

    public async createRollout() {
      this.activeOverrides = this.createOverrides = [createOverride(this.selectedFeature)]

      this.$router.push(`/features/${this.projectId}/${this.featureId}/rollouts/advanced/create/0`)

      setTimeout(() => {
        this.appStore.toggleNavDrawer('rollout')
      }, 100)
    }

    @Debounce(10)
    private updateActiveOverrides() {
      this.groupOverrides = []

      let activeOverrides: Override[] | undefined

      for (const o of this.selectedFeature?.overrideList || []) {
        if (o.oneOf?.$case === 'group') {
          for (const go of o.oneOf.group.overrides) {
            if (
              (this.rolloutIdx && go.metadata?.uid === this.rolloutId) ||
              (this.rolloutView && go.metadata?.informative?.labels?.rollout === this.rolloutView)
            ) {
              this.groupOverrides = o.oneOf.group.overrides

              if (o.oneOf.group.metadata?.uid?.startsWith('all_default_feature_rollouts')) {
                activeOverrides = [go] // Default group
              } else {
                activeOverrides = [...o.oneOf.group.overrides]
              }
            }
          }
        } else if (o.oneOf?.$case === 'override') {
          if (
            (this.rolloutIdx && o.oneOf.override.metadata?.uid === this.rolloutId) ||
            (this.rolloutView && o.oneOf.override.metadata?.informative?.labels?.rollout === this.rolloutView)
          ) {
            activeOverrides = [o.oneOf.override]
          }
        }
      }

      if (activeOverrides && this.createOverrides.length) {
        this.createOverrides = []
      }

      this.activeOverrides = this.editedOverrides.length
        ? this.editedOverrides
        : activeOverrides || this.createOverrides
    }

    @Debounce(10)
    private updateLinkedOverrides() {
      let linkedOverrides: Reference[] = []

      const firstOverride = this.groupOverrides[0] || this.activeOverrides[0]

      const rangePredicate =
        firstOverride?.criteria?.oneOf?.$case === 'and' &&
        firstOverride.criteria.oneOf.and.expressions.find(
          (e) =>
            e.oneOf?.$case === 'predicate' &&
            e.oneOf?.predicate.oneOf?.$case === 'user' &&
            e.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range',
        )

      const sortingKeyName =
        (firstOverride?.rolloutOneOf?.$case === 'rollout' &&
          firstOverride.rolloutOneOf.rollout.userSortingKeyRef &&
          firstOverride.rolloutOneOf.rollout.userSortingKeyRef.name) ||
        (rangePredicate &&
          rangePredicate?.oneOf?.$case === 'predicate' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.$case === 'user' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'range' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.user?.oneOf?.range.userSortingKey?.name)

      // TODO: fix this to make sure the order of overrides is correct, by using group info?
      if (sortingKeyName) {
        for (const f of this.allFeatures) {
          if (f.metadata?.name !== this.featureId || getProjectKey(f) !== this.projectId) {
            const overrides: Override[] = []

            forEachFeatureOverride(f, (o) => {
              if (
                (o.rolloutOneOf?.$case === 'rollout' &&
                  o.rolloutOneOf.rollout.userSortingKeyRef &&
                  o.rolloutOneOf.rollout.userSortingKeyRef.name === sortingKeyName) ||
                (o.rolloutOneOf?.$case === 'namedRolloutRef' &&
                  o.rolloutOneOf.namedRolloutRef.name === sortingKeyName) ||
                (o.criteria?.oneOf?.$case === 'and' &&
                  o.criteria.oneOf.and.expressions.find(
                    (e) =>
                      (e.oneOf?.$case === 'predicate' &&
                        e.oneOf?.predicate.oneOf?.$case === 'user' &&
                        e.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range' &&
                        e.oneOf.predicate.oneOf.user.oneOf.range.userSortingKey?.name) === sortingKeyName,
                  ))
              ) {
                overrides.push(o)
              }
            })

            if (overrides.length) {
              linkedOverrides.push({ feature: f, overrides })
            }
          }
        }
      }

      this.linkedOverrides = linkedOverrides
    }

    @Debounce(10)
    private checkForExistingOverride() {
      if (
        this.rolloutView &&
        this.selectedFeature &&
        !findFeatureOverride(this.selectedFeature, this.rolloutView) &&
        this.createOverrides[0]?.metadata?.informative?.labels?.rollout !== this.rolloutView
      ) {
        this.createOverrides = [this.createNewRolloutPlaceholder(this.rolloutView)]
      } else if (
        this.createOverrides.length &&
        this.createOverrides[0]?.metadata?.informative?.labels?.rollout !== this.rolloutView
      ) {
        this.createOverrides = []
      }
    }

    private createNewRolloutPlaceholder(target: string) {
      console.info('Creating rollout', target)

      const override = createOverride(this.selectedFeature)

      if (
        target === 'ouranians' &&
        this.selectedFeature?.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
        this.selectedFeature.metadata.contextSpec.oneOf.contexts.contexts!.find(
          (c) => c === Context.UNAUTHENTICATED_DEVICE,
        )
      ) {
        updateOverridesFromTemplate([override], 'percentage-devices', this.latestVersions)
      } else {
        updateOverridesFromTemplate([override], 'percentage-' + target, this.latestVersions)
      }

      override.metadata!.informative!.labels.rollout = target

      if (override.parameters.enabled.oneOf?.$case === 'boolean') {
        override.parameters.enabled.oneOf.boolean = true
      }

      override.metadata!.informative!.referenceUrls = [
        'https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management',
      ]

      switch (target) {
        case 'sandbox':
          override.metadata!.uid = `default_sandbox_apps_rollout_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for sandbox apps rollout.'

          break

        case 'staging':
          override.metadata!.uid = `default_staging_app_rollout_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for staging apps rollout.'

          break

        case 'release':
          override.metadata!.uid = `default_release_app_rollout_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for release apps rollout.'

          break

        case 'ouranians':
          override.metadata!.uid = `default_ouranians_only_rollout_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for Ouranians only rollout.'

          break

        case 'experimental':
          override.metadata!.uid = `default_experimental_app_rollout_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for experimental apps rollout.'

          break

        case 'release-ios':
          override.metadata!.uid = `default_release_app_rollout_ios_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for iOS release app rollout.'

          break

        case 'release-android':
          override.metadata!.uid = `default_release_app_rollout_android_${nanoId()}`

          override.metadata!.informative!.description = 'Created by Waltari for Android release app rollout.'

          break
      }

      return override
    }
  }

  export default toNative(FeatureView)
</script>
